.c6-widget-sticky {
	position: sticky;
	top: -2.5rem;
	z-index: 20;
	margin: 0;
}

.c6-widget-header {
	margin: 0 !important;
	padding: 10px 20px;
	background-color: var(--content-light-color);
	border-bottom: 1px solid var(--line-color);
}

.c6-widget-header:empty {
	display: none;
}