/* User Controls */
.c6-user-controls {
	grid-row: 1;
    grid-column-end: -1;
    grid-column-start: -2;
	height: 36px;
	user-select: none;
	cursor: default;

	display: flex;
	justify-content: flex-end;
}
.c6-user-controls > .wrapper {
	position: relative;
	padding: 10px;
	white-space: nowrap;
}

.c6-user-controls a {
	cursor: pointer;
}

.c6-user-controls .wrapper > span,
.c6-user-controls .wrapper > a {
	color: var(--action-text-color);
	margin-left: 10px;
	text-decoration: none;
	font-size: 14px;
}

.c6-user-controls span:before,
.c6-user-controls a:before {
    font-size: 1.5rem;
    position: relative;
    top: .25rem;
    line-height: 0;
}

/* .c6-user-controls a span:before {
	top: 3px;
	font-size: 18px;
} */

.c6-user-controls .icon-cancel:hover:before {
	color: #f44336;
	cursor: pointer;
}

.c6-user-controls .settings-area {
	text-align: left;
	position: absolute;
	right: 0;
	top: 100%;
	min-width: 100%;
	width: auto;
	min-height: 50px;
	padding: 10px;

	background-color: var(--area-color);
	color: white;

	border-radius: 0 0 0 var(--comet-border-radius);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.c6-user-controls .settings-area h1 {
	font-size: 1.2rem;
	margin: 0;
}

.c6-user-controls .settings-area td {
	white-space: nowrap;
}

.c6-user-controls .settings-toggle,
.c6-user-controls .beacon-toggle {
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.c6-user-controls .settings-toggle:hover,
.c6-user-controls .beacon-toggle:hover {
	color: var(--area-hl-color);
}


/* What's new? */
.c6-user-controls .whats-new {
	display: inline-block;
	position: relative;
	color: var(--text-color);
}

.c6-user-controls .whats-new > a {
	color: white;
}

.c6-user-controls .whats-new > div {
	position: absolute;
	z-index: 1000;
	right: calc(100% - 53px);
	top: 100%;
	min-width: 350px;
	max-width: 500px;
	width: auto;
	max-height: 800px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 10px;
	white-space: pre-line;

	background: white;
	border: 2px solid var(--label-color);
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16),
				0 2px 4px rgba(0, 0, 0, 0.23);
}

.c6-user-controls .whats-new > div:after,
.c6-user-controls .whats-new > div:before {
	bottom: 100%;
	left: calc(100% - 30px);
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.c6-user-controls .whats-new > div:after {
	border-color: transparent;
	border-bottom-color: white;
	border-width: 15px;
	margin-left: -15px;
}

.c6-user-controls .whats-new > div:before {
	border-color: rgba(205, 220, 57, 0);
	border-bottom-color: var(--label-color);
	border-width: 18px;
	margin-left: -18px;
}

.c6-user-controls .whats-new > div > a {
	color: unset;
	border: none;
	position: absolute;
	top: 15px;
	right: 18px;
}

.c6-user-controls .whats-new h4,
.c6-user-controls .whats-new p {
	margin: 3px 0;
}





.c6-user-controls .close {
	margin: 10px 0 0;
}