.c6-content {
	padding: 10px;
	/*transition: background-color .15s ease-in, color .15s ease-in;*/
	/*position: relative;*/ /* REMOVED FOR: https://bugs.chromium.org/p/chromium/issues/detail?id=605119&can=2&start=0&num=100&q=draggable&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Component%20Status%20Owner%20Summary%20OS%20Modified&groupby=&sort= */
	align-self: center;
}

.c6-item.small .c6-content {
	padding: 7px 10px;
}

.c6-content h1,
.c6-content h2 {
	margin: 0 .25rem 0 0.25rem;
	font-size: 1rem;
	display: inline-block;
}

.c6-content > p {
	margin: 0.15rem 0 0 0.25rem;
	font-size: 1rem;
	color: var(--text-light-color);
	/*overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;*/
}

.c6-content.c6-content-action {
	white-space: nowrap;
}

/* *********** Content actions *********** */
.c6-content-actions {
	display: flex;
}

.c6-content-actions > * {
	-webkit-appearance: none;
	background: none;
	border: none;
	font-family: inherit;
	font-size: inherit;
	border-radius: 0;
	padding: 0 15px;
}

.c6-content-actions > button:not([disabled]),
.c6-content-actions > a {
	cursor: pointer;
}


.c6-content-actions [class^="icon-"]:before {
	opacity: 0;
	padding-right: 3px;
	transition: none;
}

.c6-item:hover [class^="icon-"]:before {
	opacity: 1;
	transition: opacity .2s ease-in-out;
}