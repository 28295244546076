/* App */
.c6-app {
	display: flex;

	height: 100%;
	box-sizing: border-box;
}

/* TODO Remove when Match app is not active anymore */
.c6-app > section + section {
	margin-left: 20px;
}

.c6-modal-body .c6-app > section {
	margin: 0;
}

.c6-app .c6-filter h1 + * {
	/*line-height: 1.5rem;*/
	/* margin: 0; */
	margin: 0 0 5px 0;
}

.c6-app.flex-dir-row {
	flex-direction: row; /* default */
}

.c6-app.flex-dir-column {
	flex-direction: column;
}

/* Simple grid layout (header and scrollable content area) */
.c6-app.c6-layout-grid,
.c6-mainapp.c6-layout-grid,
.c6-subapp.c6-layout-grid {
	height: calc(100vh - 36px);
	display: grid;
	grid-template-rows: auto 1fr;
}

.c6-side-navigation + .c6-app.c6-layout-grid,
.c6-side-navigation + .c6-mainapp.c6-layout-grid,
.c6-side-navigation + .c6-subapp.c6-layout-grid {
	height: 100%;
}

/* Grid layout with an extra top area (two headers and scrollable content area) */
.c6-app.c6-layout-grid.area-top {
	grid-template-rows: auto auto 1fr;
}

/* Complex layout (above + side area) */
.c6-app.c6-layout-grid.area-right {
	grid-column-gap: 20px;
	grid-template-columns: 2fr 1fr;
	grid-template-areas:
	  "header right"
	  "content right";
}

.c6-app.c6-layout-grid.area-right.area-info {
	grid-column-gap: 20px;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas:
	  "header right"
	  "info right"
	  "content right";
}

.c6-app.c6-layout-grid.area-right > .c6-filter {
	grid-area: header;
}

.c6-app.c6-layout-grid.area-right > .c6-infobar {
	grid-area: info;
}

.c6-app.c6-layout-grid.area-right > .c6-main {
	grid-area: content;
}

.c6-app.c6-layout-grid.area-right > .c6-subapp {
	grid-area: right;
}

.c6-app > .c6-spinner {
	z-index: 30;
}
.c6-side-navigation + .c6-app > .c6-spinner {
	display: none;
}

/* TODO: Fix grid height on non-appbar apps (like external-schedule) */
/* .c6-app.c6-layout-grid {
	height: 100vh;
}

.c6-module > nav ~ .c6-app.c6-layout-grid {
	height: calc(100vh - 36px);
} */