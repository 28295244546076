/* Scrolling */
.scroll > * {
	height: calc(100vh - 111px); /* 111 = 36 (nav) + 20+35+20 (margin+c6-filter+margin) TODO: Calculate dynamically? */
	overflow-y: scroll;
	padding-bottom: 55px;
}

/* Scrolling in editors */
.c6-editor .scroll > * {
	overflow-y: auto; /* Set to scroll for 2 years, but auto seems better (get's rid of margin when there is no scroll) */
}

/* Let the browser know that things will happen soon => greatly improved FPS */
/* See more: https://stackoverflow.com/questions/39261456/reducing-scroll-jank-when-using-overflow-y-scroll */
.c6-app.c6-list .scroll > * {
	will-change: transform;
}

::-webkit-scrollbar {
	width:  5px;
	height: auto;
}

::-webkit-scrollbar-thumb {
	background: var(--action-color);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--action-hl-color);
}

.scroll-track-bg::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, 0.1);
}

.scroll > *::-webkit-scrollbar-track {
	margin-bottom: calc(2.5rem + 20px);
}

.scroll.pad26 > *::-webkit-scrollbar-track {
	margin-top: 26px;
}

.scroll.pad10 > *::-webkit-scrollbar-track {
	margin-top: 10px;
}

.scroll.pad26 > * {
	padding-bottom: 35px;
}

/* Scrolling in modals */
.c6-modal-body .scroll > * {
	padding-bottom: 0;
}

.c6-modal-body .scroll > *::-webkit-scrollbar-track {
	margin-bottom: 0;
}


/* When we have small screen widths we want to scroll the whole page for better UX */
@media screen and (max-width: 450px) {
	.scroll > * {
		overflow-y: initial;
		height: auto !important;
	}
}