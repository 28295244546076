
.c6-clickable {
	cursor: pointer;
}

.c6-item.c6-clickable:hover {
	background-color: var(--content-hl-light-color);
}

.c6-item > .c6-clickable:hover {
	background-color: var(--content-hover-color);
}

/* *********** Cover actions (DEPRECATED) *********** */
.c6-actions .cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	text-align: center;
}

.c6-actions .cover:hover {
	background-color: var(--content-hover-color);
}

.c6-actions .cover span {
	position: absolute;
	overflow: hidden;
	top: 0;
	right: 0;
	height: 0;
	opacity: 0;
	display: block;

	background-color: var(--action-color);


	color: #fff;
	text-transform: uppercase;
	font-size: .75rem;
	line-height: 1rem;
	padding: 0 3px;

	transition: height ease-in-out .2s, opacity ease-in .2s;
	transition-delay: 0, 0;
}

.c6-actions .cover:hover span {
	transition-delay: 1s;
	opacity: 1;
	height: 15px;
	z-index: 25;
}

/* *********** Link actions *********** */
.c6-actions-link {
	display: flex;
	min-height: 0;
	max-height: 0;
	position: relative;
	z-index: 15;
	background-color: var(--action-color);
	vertical-align: middle;
	transition: min-height .15s ease-in-out
}

/*.is-dragging-item .c6-actions-link {
	display: none;
}*/

.c6-item:hover .c6-actions-link {
	/*max-width: 100%;*/
	min-height: 2.5rem;
	max-height: auto;
	transition: min-height .15s .2s ease-in-out;
}

.c6-item .c6-actions-link:empty {
	display: none;
}

.c6-actions .link {
	background-color: transparent;
	color: #fff;
	opacity: 0;
	border: 0;
	cursor: pointer;
	font-size: 1rem;
	margin-right: 15px;
	transition: opacity .15s ease-in;
}

.c6-item:hover .c6-actions .link {
	opacity: 1;
	transition: opacity .15s .35s ease-in;
}

.c6-item:hover .c6-actions .link.disabled {
	opacity: 0.45;
}

.c6-actions .link:first-child {
	margin-left: 5px;
}

.c6-actions .link.dangerous {
	margin-left: auto;
}

.c6-actions .link:hover {
	color: #CDDC39;
}

.c6-actions .link.dangerous:hover {
	color: #f44336;
}

.c6-actions [class^="icon-"]:not(:empty):before,
.c6-actions [class*=" icon-"]:not(:empty):before {
	margin-right: .25rem;
}

/* *********** Inline actions *********** */
.c6-contentItem .c6-content.c6-actions-menu {
	padding: 0;
}

.c6-actions-menu .large-icon {
	padding: 10px 5px 10px 10px;
	color: var(--action-color);

}

/* *********** Inline actions *********** */
.c6-item .c6-content + .c6-actions {
	display: flex;
	align-self: center;
	padding: 10px 0;
}

.c6-actions-inline  {
	align-self: center;
	width: max-content;
}

.c6-actions-inline button {
	border: 0;
	background-color: transparent;
	cursor: pointer;
	color: var(--action-color);
	font-size: 1rem;
}

.c6-actions-inline button:hover {
	color: var(--action-hl-color);
}

/* .c6-actions-inline button.no-title span {
	line-height: 1.5rem;
} */
.c6-actions-inline button.no-title span:before {
	font-size: 1.5rem;
}

/* No actions during drag */
.is-dragging-item .c6-actions {
	display: none !important;
}

.is-dragging-item .c6-item {
	margin-top: 0 !important;
}

/* Move thumbnail inline actions to a top right triangle */
.c6-itemGroup.layout-thumbnails .c6-actions-inline {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.c6-itemGroup.layout-thumbnails .c6-item.sel .c6-actions-inline,
.c6-itemGroup.layout-thumbnails .c6-item:hover .c6-actions-inline {
	display: inherit;
}

.c6-itemGroup.layout-thumbnails .c6-actions-inline:not(:empty):before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;

	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 45px 45px 0;
	border-color: transparent var(--action-color) transparent transparent;
}

.c6-itemGroup.layout-thumbnails .c6-actions-inline > * {
	color: var(--accent-color);
	z-index: 5;
	padding: 2px;
}

.c6-itemGroup.layout-thumbnails .c6-actions-inline > *:hover {
	color: var(--area-hl-color);
}