/* ================== */
/* ===== GENERAL ==== */
/* ================== */
.todo-files > .c6-main {
	margin-right: -5px;
}

.todo-files > .c6-main > button {
	display: block;
	width: 100%;
	height: 4rem;
	padding: 10px 0;
	margin: 0;

	background-color: var(--content-bg-color);
}

.todo-files > p {
	margin: 0 0 4px;
	font-size: 1em;
}



/* ================== */
/* ==== CONTAINER === */
/* ================== */
.todo-files .container {
	background-color: var(--content-bg-color);
	border-bottom: 2px solid var(--bg-color);
	padding: 15px;
}

.todo-files .container .container-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
}

.todo-files .container .container-header .content-buttons {
	white-space: nowrap;
}

.todo-files .container h2 {
	margin: 4px 0 0;
}

.todo-files .container .approved-info {
	color: var(--success-color);
	margin-left: 5px;
	font-size: 0.9em;
}

.files {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 15px;
}

.files.container-disabled .asset {
	opacity: 0.7;
}

.files .file-wrapper {
	position: relative;
	padding-top: 100%;
}

.files .file-wrapper > .uploader,
.files .file-wrapper > .asset {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.files .asset:not(.document),
.files .asset:not(.document) > .asset-text,
.files .asset:not(.document) img {
	cursor: default;
}

.files .uploader .upload-instructions > .c6-spinner {
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
}
.files .uploader .upload-instructions > .c6-spinner > span {
	background: linear-gradient(to right, var(--action-color) 10%, white 50%);
}
.files .uploader .upload-instructions > .c6-spinner > span:after {
	background: white;
}
.files .uploader .upload-instructions > .c6-spinner > span:before  {
	background: var(--action-color);
}




/* =================== */
/* == MEDIA QUERIES == */
/* =================== */
@media screen and (max-width: 1900px) {
	.todo-files .container {
		padding: 10px;
	}
	.files {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
	}
}

@media screen and (max-width: 1285px) {
	.todo-files .container {
		padding: 5px;
	}
	.files {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5px;
	}
}