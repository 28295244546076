.c6-label {
	display: inline-block;
	border-radius: .75em;
	padding: 2px 5px;
	color: #fff;
	background-color: var(--label-color); /* --area-color */
	font-size: 0.85rem;
	line-height: 1rem;
}

.c6-label-large {
	font-size: 1rem;
	border-radius: .25em;
}

.c6-label + .c6-label {
	margin-left: 5px;
}

.c6-label[class*=" icon-"]:before {
	margin-right: 3px;
}

.c6-label.icon-sharewithpeople:before {
	position: relative;
	top: 1px;
}