.c6-authentication {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100vh !important;
	overflow-y: auto !important;
	background: var(--login-bg-color) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="594.494px" height="388.05px" viewBox="0 0 594.494 388.05" enable-background="new 0 0 594.494 388.05" xml:space="preserve"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="297.2466" y1="0" x2="297.2466" y2="388.0503"><stop offset="0.65" style="stop-color:#FFFFFF"/><stop offset="0.65" style="stop-color:#FFFFFF;stop-opacity:0.35"/><stop offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/></linearGradient><path fill="url(#SVGID_1_)" d="M147.423,251.186c0-151.419,246.318-228.272,447.071-228.97V0C286.768,1.008,0,155.164,0,251.186c0,40.557,51.163,91.485,133.454,136.864h98.243C180.25,352.772,147.423,307.23,147.423,251.186z"/></svg>') right bottom no-repeat;
}

.c6-authentication-form header {
	background-color: var(--login-dialog-header-bg-color);
	padding: 20px;
}

.c6-authentication-form header > a {
	display: inline-block;
	text-transform: uppercase;
}

.c6-authentication-form h1 {
	margin: 0;
	line-height: 24px;
}

.c6-authentication-form .login-greeting {
	background-color: var(--modal-area-color);
	margin: 0;
	padding: 20px;
	font-weight: normal;
}

.c6-authentication-error header {
	background-color: var(--error-color);
}
.c6-authentication-error h1 {
	color: var(--error-fg-color);
	font-size: inherit;
}

.c6-authentication-form,
.c6-authentication-form > form {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
}

.c6-authentication-form > form > div:not(.form-bottom) > div,
.c6-authentication-form .form-body {
	padding: 0 20px;
}

.c6-authentication-form .form-bottom {
	padding: 0 20px 20px 20px;
}

.c6-authentication-form .form-bottom > div {
	display: inline-block;
	width: 50%;
}

.c6-authentication-form .form-bottom a {
	line-height: 36px;
	font-size: 12px;
	text-transform: uppercase;
}

.c6-authentication-form .form-bottom button {
	background-color: var(--action-color) !important;
}

.c6-authentication-form p:not(.MuiFormHelperText-root) {
	margin: 0;
	padding: 15px 20px 0;
}


.c6-authentication > div {
	width: 300px;
	min-height: 270px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	background-color: var(--login-dialog-bg-color);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px,
				rgba(0, 0, 0, 0.12) 0px 1px 4px;
	border-radius: var(--comet-border-radius);
	overflow: hidden;
}
.c6-authentication > div:only-child {
	margin: auto;
}

.c6-authentication > header,
.c6-authentication > footer {
	background-color: var(--bar-transparent-color);
	opacity: 0.9;
	color: white;
	padding: 20px;
	max-width: unset;
	flex-shrink: 0;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.c6-authentication > footer {
	order: 2;
}

.c6-authentication > header > h1,
.c6-authentication > footer > h1 {
	background-color: transparent;
	text-align: center;
	font-size: 2.5rem;
	margin: 10px;
	padding: 0;
}

.c6-authentication > header > p,
.c6-authentication > footer > p {
	max-width: 800px;
}

.c6-authentication > header a,
.c6-authentication > footer a {
	display: inline-block; /* Prevent line break in email address */
	color: white;
}

.c6-authentication > header a:hover,
.c6-authentication > footer a:hover {
	background-color: white;
	color: var(--action-hl-color);
}


.c6-authentication-form .c6-authentication-input {
	margin: 15px 0 10px;
}

.c6-authentication-form .loading-icon {
	color: white;
}



.c6-authentication-form.user-controls {
	min-width: 300px;
	background-color: var(--login-dialog-bg-color);
}


.c6-authentication-form.user-controls header {
	text-align: center;
}

.c6-authentication-form.user-controls h2 {
	font-weight: normal;
}

.c6-authentication-form.user-controls .icon-person {
	font-size: 3rem;
}

.c6-authentication-form.user-controls .form-body {
	display: flex;
	flex-wrap: wrap;
}
.c6-authentication-form.user-controls .form-body > div {
	flex: 1;
	min-width: 300px;
}
.c6-authentication-form.user-controls .account-settings {
	padding-right: 20px;
	padding-bottom: 10px;
}
.c6-authentication-form.user-controls .preferences {
	padding-left: 20px;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.c6-authentication-form.user-controls .preferences > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	min-height: 40px;
}

.c6-authentication-form.user-controls .form-bottom {
	text-align: center;
	margin-top: 0;
	padding-top: 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}