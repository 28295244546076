/* Module */
.c6-module {
	height: 100%;
}

/*.c6-module {
	height: 100%;
	box-sizing: border-box;

	padding-top: 36px;
	padding-bottom: 36px;
}*/

.c6-module .c6-topbar {
	color: var(--action-text-color);
	background-color: var(--area-color);
	user-select: none;
	min-height: 36px;
	position: relative;
	z-index: 25;
	width: 100%;
}

.c6-topbar .topbar-wrapper {
	width: 100vw;
	display: grid;
	grid-template-rows: 36px;
	grid-template-columns: minmax(200px, 250px) 1fr minmax(200px, 250px);
}

.c6-module .c6-topbar nav {
	grid-row: 1;
	grid-column: 2;
}

.c6-module .c6-topbar nav.hidden {
	visibility: hidden;
	pointer-events: none;
	overflow: hidden;
	max-height: 0;
	min-height: 0;
}

.c6-module .c6-topbar nav > .module-apps {
	padding: 0;
	display: flex;
    justify-content: center;
}

.c6-module .c6-topbar nav a {
	white-space: nowrap;
	color: currentColor;
	padding: 10px;
	text-decoration: none;

	display: inline-flex;
	align-items: center;
	flex-wrap: nowrap;

	/*transition: background-color .15s ease-in-out;*/
}
.c6-module .c6-topbar nav a .name {
	padding: 0 .25rem;
	display: inline-block;
}

.c6-module .c6-topbar nav a:before {
	font-size: 24px; /* 1.5rem; */
	position: relative;
	line-height: 0;
}

.c6-module .c6-topbar nav a:hover,
.c6-module .c6-topbar nav a.active {
	color: var(--area-hl-color);
	/*background-color: var(--action-color);*/
	text-decoration: none;
	box-shadow: inset 0 -3px 0 var(--area-hl-color);
}

.c6-module .c6-topbar nav a:not(.active):hover {
	/*background-color: var(--action-hl-color);*/
	color: var(--area-hl-color);
	box-shadow: none;
}

.c6-module > .c6-app {
	/*margin-left: 20px;
	margin-right: 20px;*/
	padding-left: 20px;
	padding-right: 20px;
}



.c6-module .c6-topbar nav.slim a[class^="icon"]:not(.active) .name {
	max-width: 0;
	opacity: 0;
	overflow: hidden;
	transition: max-width .5s ease-out,
				opacity .5s ease-out;
}
.c6-module .c6-topbar nav.slim a[class^="icon"]:hover .name {
	max-width: 200px;
	opacity: 1;
}

@media screen and (max-width: 1024px) {
	.c6-module .c6-topbar .topbar-wrapper {
		min-height: 72px;
		grid-template-rows: repeat(2, 36px);
		grid-template-columns: repeat(2, 1fr);
	}

	.c6-module .c6-topbar nav {
		grid-row: 2;
		grid-column: 1 / -1;
	}
}

/* When we have small screen widths we want to scroll the whole page for better UX */
@media screen and (max-width: 450px) {
	.c6-module .c6-topbar {
		position: fixed;
	}

	.c6-module .c6-topbar + .c6-app {
		padding-top: 72px;
	}

	.c6-module .c6-topbar + .c6-welcome {
		padding-top: 108px;
	}
}