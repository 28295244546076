
/* ============================= */
/* halfWidth editorWidget option */
/* ============================= */
.flatpickr-wrapper.half-width {
	width: calc(50% - 40px);
	float: left;
}

.flatpickr-wrapper.half-width > .c6-picker > .textfield {
	width: 100% !important;
}

.flatpickr-wrapper.half-width > .c6-picker > .textfield > div {
	width: 100% !important;
}



/* ============================= */
/* thirdWidth editorWidget option */
/* ============================= */
.flatpickr-wrapper.third-width {
	width: calc(33% - 40px);
	float: left;
}

.flatpickr-wrapper.third-width > .c6-picker > .textfield {
	width: 100% !important;
}

.flatpickr-wrapper.third-width > .c6-picker > .textfield > div {
	width: 100% !important;
}



/* ========================================================== */
/* Flatpickr calendar (make it look more material-design-ish) */
/* ========================================================== */
body .flatpickr-calendar {
	border-radius: 3px;
	box-shadow:
		rgba(0, 0, 0, 0.156863) 0px 3px 10px,
		rgba(0, 0, 0, 0.227451) 0px 3px 10px;
}

.c6-theme-dark body .flatpickr-calendar {
	background-color: var(--content-secondary-color);
}

body .flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
	border-color: transparent;
}

.flatpickr-calendar .flatpickr-month {
	background: var(--area-color);
	color: white;
	border-radius: var(--comet-border-radius) var(--comet-border-radius) 0 0;
}
.flatpickr-calendar .flatpickr-month svg > path,
.flatpickr-calendar .flatpickr-prev-month svg > path,
.flatpickr-calendar .flatpickr-next-month svg > path {
	fill: white;
	stroke-width: 20%;
}

.flatpickr-calendar .flatpickr-prev-month:hover svg > path,
.flatpickr-calendar .flatpickr-next-month:hover svg > path {
	fill: var(--area-hl-color);
	stroke-width: 20%;
}

.flatpickr-calendar .flatpickr-weekdays {
	background: var(--area-color);
}
.flatpickr-calendar .flatpickr-weekdays > span,
.flatpickr-calendar .flatpickr-weekdaycontainer > span {
	color: white;
}

.flatpickr-calendar .flatpickr-days {
	padding-top: 3px;
}

.flatpickr-calendar .flatpickr-current-month > .cur-month {
	cursor: default;
}
.flatpickr-calendar .flatpickr-current-month > span.cur-month:hover {
	background: inherit;
}
.flatpickr-calendar .flatpickr-current-month option.flatpickr-monthDropdown-month {
	background-color: var(--area-lighter-color);
	color: var(--text-color);
}

.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange:hover,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay {
	background: var(--action-color);
	color: #fff;
	border-color: var(--action-color);
}

.flatpickr-calendar .flatpickr-weekday {
	color: white;
	background-color: var(--area-color);
}

.flatpickr-calendar .flatpickr-weeks {
	padding-top: 4px;
}

.flatpickr-confirm,
.flatpickr-go-today {
	height: 40px;
	max-height: 0;
	visibility: hidden;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: var(--content-light-color);
	width: 100%;
	float: left;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.flatpickr-confirm.visible.has-sibling {
	width: 50%;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.flatpickr-confirm.visible ~ .flatpickr-go-today {
	width: 50%;
}

.flatpickr-go-today.visible {
	visibility: visible;
	max-height: 40px;
}

/* ====================== */
/* Buttons (open / clear) */
/* ====================== */
.c6-picker .button-wrapper {
	position: absolute;
	right: 0;
	bottom: 10px;
}

.c6-picker .button-wrapper > button {
	margin-left: 0;
	margin-right: 0;
	min-width: 2rem;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
}

.c6-picker .button-wrapper > button:before {
	font-size: 1.4rem;
	transition: color .2s ease-out;
	color: var(--text-color);
	line-height: 2rem;
}

.c6-picker .button-wrapper > button[data-toggle]:hover:before {
	color: var(--action-hl-color);
}

.c6-picker .button-wrapper > button[data-clear]:hover:before {
	color: var(--attention-color);
}

/* ============================= */
/*    HTML input field fixes     */
/* ============================= */
.flatpickr-wrapper {
	width: 100%;
	/* height: 72px;
	display: flex; */
}

/* HACK: For date fields in the RJSF-form */
.c6-editor .form-group > .flatpickr-wrapper {
	width: calc(100% - 40px);
	max-width: 240px;
}

.c6-picker {
	/* align-self: flex-end; */
	width: 100%;
	position: relative;

	font-size: 16px;
	margin: 0;
	/* padding: 20px 0 7px 0; */
	padding: 41px 0 7px 0;
}

.c6-range-picker {
	min-width: 240px;
}

.c6-picker > input {
	width: 100%;

	background: none;
	border: none;
	border-bottom: 1px solid var(--text-lighter-color); /* rgba(0, 0, 0, 0.1); */

	padding-bottom: 3px;

	font-family: var(--default-font);
	font-size: 16px;
}

.c6-picker > input ~ .c6-picker-border:after {
	content: "";
	display: block;
	position: absolute;
	/* bottom: 20px; */
	bottom: 7px;
	left: 50%;
	background-color: transparent;
	height: 2px;
	max-width: 100%;
	width: 0;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.c6-picker > input:active ~ .c6-picker-border:after,
.c6-picker > input:focus ~ .c6-picker-border:after {
	background-color: var(--action-color);
	max-width: 100%;
	width: 100%;
	left: 0;
}

.c6-picker > input ~ .button-wrapper {
	/* bottom: 20px; */
	bottom: 7px;
}

.c6-picker-floating-label {
	bottom: 0;
    color: var(--text-lighter-color);
    font-size: 16px;
    left: 0;
    right: 0;
    pointer-events: none;
    position: absolute;
    display: block;
    /* top: 21px; */
    top: 39px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.c6-picker > input:focus ~ label,
.c6-picker > input[data-hasvalue="true"] ~ label {
	color: var(--action-color);
	font-size: 12px;
	/* top: 0; */
	top: 22px;
	visibility: visible;
}

.c6-picker > input[data-hasvalue="true"]:not(:focus) ~ label {
	color: var(--text-lighter-color);
}


.c6-theme-dark .c6-editor .c6-picker > input {
	color: var(--text-color);
}
.c6-theme-dark .c6-picker > input {
	color: var(--text-lighter-color);
}

.c6-theme-dark .flatpickr-calendar .flatpickr-month,
.c6-theme-dark .flatpickr-calendar .flatpickr-weekday,
.c6-theme-dark .flatpickr-calendar .flatpickr-weekdays {
	background: var(--modal-area-color);
}

.c6-theme-dark .flatpickr-calendar .flatpickr-days {
	background: var(--modal-area-light-color);
}

.c6-theme-dark .flatpickr-calendar .flatpickr-days .flatpickr-day {
	color: var(--text-color);
}
.c6-theme-dark .flatpickr-calendar .flatpickr-days .flatpickr-day.inRange {
	background-color: var(--area-color);
	color: var(--action-text-color);
	box-shadow: -5px 0 0 var(--area-color),
				5px 0 0 var(--area-color);
	border: none;
}
.c6-theme-dark .flatpickr-calendar .flatpickr-day.nextMonthDay,
.c6-theme-dark .flatpickr-calendar .flatpickr-day.prevMonthDay {
	color: var(--color-primary-200);
}

.c6-theme-dark .flatpickr-calendar .flatpickr-weeks {
	background: var(--area-lightest-color);
}

.c6-theme-dark .flatpickr-calendar .flatpickr-weeks .flatpickr-day {
	color: var(--color-primary-100);
}