.hide-legend > fieldset > legend {
	display: none !important;
}

.c6-osd-metadata-preview p {
	margin: 5px 0;
}
.c6-osd-metadata-preview p + p {
	margin-top: 0;
	margin-bottom: 10px;
}

/* CREW */
.c6-osd-metadataeditor .crew-with-padding {
	padding-bottom: 250px;
}