
.c6-star-asset-editor .asset-sidebar {
	grid-column: 1 / 1;
	padding: 0 10px;

	display: flex;
	flex-direction: column;

}



/* Crop previews */
.asset-sidebar .crop-previews > p {
	font-size: 14px;
	background-color: var(--bg-dark-color);
	padding: 10px;
	margin: 0;
}

.asset-sidebar .crop-previews .c6-img {
	cursor: pointer;
}

.asset-sidebar .crop-previews .preview {
	margin-bottom: 10px;
	position: relative;
	/* animation: slidein 150ms ease-out; */
}

.asset-sidebar .crop-previews .preview:last-child {
	margin-bottom: 0;
}

.asset-sidebar .crop-previews img {
	display: block;
	width: 100%;
	height: auto;
}

.asset-sidebar .preview.sel img {
	filter: sepia(100%) hue-rotate(135deg) saturate(3) brightness(75%)
}

.asset-sidebar .crop-previews .preview .c6-img + div {
	position: absolute;
	bottom: 3px;
	left: 3px;
	color: #fff;
	background-color: var(--attention-color);
	font-size: 14px;
	padding: 0 2px;
}

.asset-sidebar .preview.has-crop .c6-img + div {
	background-color: var(--ok-color);
}

.asset-sidebar .preview.approved .c6-img + div:before {
	margin-right: 3px;
}

.asset-sidebar .crop-previews .c6-spinner {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.asset-sidebar .crop-previews .c6-spinner > span {
	height: 25px;
	width: 25px;
	position: static;
}

/* TABS */
.asset-sidebar .tabs {
	display: flex;
	/* padding: 0 10px; */
	background-color: var(--bg-dark-color);
	color: var(--action-color);
	overflow: hidden;
	margin-bottom: 10px;
}

.asset-sidebar .tabs .tab {
	position: relative;
	flex-grow: 1;
	font-size: 2rem;
	padding: 14px 10px 10px;

	cursor: pointer;
	color: inherit;
	transition: color 250ms ease-in-out;
	text-align: center;
}
.asset-sidebar .tabs .tab.active,
.asset-sidebar .tabs .tab:hover {
	color: var(--area-hl-color);
}

.asset-sidebar .tabs .tab:after {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	display: block;
	content: "";
	border-bottom: 4px solid var(--area-hl-color);
	transform: translateY(4px);
	color: inherit;
	transition: transform 250ms ease-in-out;
}

.asset-sidebar .tabs .tab.active:after {
	transform: translateY(0);
}




/* sections */
.asset-sidebar .sections {
	margin-right: -5px;
	text-align: left;
	flex-basis: 0;
	flex-grow: 1;
	padding-bottom: 10px;
}

.asset-sidebar .sections > * {
	margin-right: 5px;
}

.asset-sidebar .c6-main {
	overflow-y: auto;
}

.asset-sidebar .c6-main::-webkit-scrollbar-track {
	margin-bottom: 10px;
}

.asset-sidebar .c6-main > * {
	will-change: unset;
}

.asset-sidebar section {
	background-color: var(--bg-dark-color);
	/* animation: slidein 150ms ease-out; */
}
.asset-sidebar section:not(:last-child) {
	margin-bottom: 10px;
}

.asset-sidebar section .header {
	padding: 10px 10px;
	font-size: 14px;
	user-select: none;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.asset-sidebar section .header > div {
	display: flex;
	align-items: center;
}

.asset-sidebar section .header a {
	font-size: 1.3rem;
	cursor: pointer;
}

.asset-sidebar section [class*="icon-"]:before {
	font-size: 1.5rem;
	margin-right: 10px;
}
.asset-sidebar section .c6-switch [class*="icon-"]:before {
	margin-right: 0;
}

.asset-sidebar section .collapsed {
	height: 0;
	overflow: hidden;
}

.asset-sidebar section:not(.exif) .content > div {
	padding: 6px 10px !important;
}

.asset-sidebar section:not(.exif):not(.branding):not(.region-select) .content > div {
	width: unset !important;
}

.asset-sidebar section .content > p {
	margin: 0;
	padding: 0 10px 10px;
	font-size: 14px;
}



/* modify */
.asset-sidebar section .rotation {
	padding: 5px 7px;
}



/* Metadata */
.asset-sidebar .content p {
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 14px;

	display: flex;
	align-items: center;

	word-wrap: break-word;
	word-break: break-all;
}
.asset-sidebar .content p:not(:first-child) {
	margin-left: 30px;
}

.asset-sidebar .content .info-summary {
	padding: 10px 10px 0;
}
.asset-sidebar .content .info-summary p:not(:first-child) {
	color: var(--text-light-color);
}
.asset-sidebar .content .info-summary p:before {
	margin-bottom: auto;
}

.asset-sidebar .c6-textarea-confirm {
	margin-top: -20px;
	overflow: hidden;
}

.asset-sidebar .content p.icon-code,
.asset-sidebar .content p.json-tree-title {
	padding: 10px 10px 4px;
	cursor: pointer;
}
.asset-sidebar .content p.json-tree-title > span {
	margin-left: auto;
	margin-right: 0;
}

.asset-sidebar ul.exif {
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	overflow: hidden;
}

.asset-sidebar ul.exif > a {
	position: absolute;
	top: 0;
	right: 0;
}

.asset-sidebar ul.exif li {
	word-break: break-word;
	padding: 5px 10px;
	list-style: none;
}

.asset-sidebar ul.exif div:first-child {
	font-size: 12px;
	font-weight: 600;
}

.asset-sidebar ul.exif div:last-child {
	color: white;
	font-size: 13px;
}

.asset-sidebar .mui-select.margin {
	margin: 5px 10px;
	width: calc(100% - 20px);
}
.asset-sidebar .mui-select {
	margin: 5px 0;
}
.asset-sidebar .mui-select .MuiInput-input,
.asset-sidebar .mui-select svg {
	color: rgb(144, 164, 174);
}

.asset-sidebar .textarea-controls {
	height: 20px;
}
.asset-sidebar .MuiInput-underline:before {
	border-color: rgb(144, 164, 174);
}
.asset-sidebar section .MuiFormLabel-root {
	color: inherit;
}
.asset-sidebar section .MuiInput-input {
	color: #fff;
}
.asset-sidebar section .MuiInput-root:hover:not(.Mui-disabled):not(.MuiInputBase-readOnly):before {
	border-bottom-color: var(--action-color);
}
.asset-sidebar section .MuiInput-root.Mui-disabled:after,
.asset-sidebar section .MuiInput-root.MuiInputBase-readOnly:after {
	border-bottom-color: transparent;
}


/* Restrictions */
.asset-sidebar .c6-star-asset-restrictions .c6-switch label {
	color: var(--action-color) !important;
	transition: color 250ms ease-in-out;
	padding: 0 8px;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch label:first-of-type {
	padding-left: 0;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch label:last-of-type {
	padding-right: 0;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch label.sel,
.asset-sidebar .c6-star-asset-restrictions .c6-switch label:hover {
	color: var(--area-hl-color) !important;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch label.dimmed,
.asset-sidebar .c6-star-asset-restrictions .c6-switch label.dimmed:hover {
	color: var(--text-light-color) !important;
	opacity: 1;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch label:before {
	padding-right: 0;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch-description {
	font-size: 1rem;
	color: var(--accent-color);
	margin-top: 8px;
	min-height: 50px;
}

.asset-sidebar .c6-star-asset-restrictions .c6-switch-description.sel {
	color: var(--area-hl-color);
}

.asset-sidebar .c6-star-asset-restrictions .c6-picker button:before {
	color: var(--action-color);
}

.asset-sidebar .c6-star-asset-restrictions .c6-textarea-confirm {
	margin-top: 0;
}

/* Animation */
/* @keyframes slidein {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
} */

/* Date pickers */
.asset-sidebar .c6-star-asset-restrictions .c6-picker input {
	font-size: 14px;
	color: var(--accent-color);
}

.asset-sidebar .c6-star-asset-restrictions .c6-picker input::placeholder {
	color: var(--text-lighter-color);
}

.asset-sidebar .c6-star-asset-restrictions .c6-picker label {
	font-size: 14px;
}

.asset-sidebar .c6-star-asset-restrictions .c6-picker > input[data-hasvalue="true"]:not(:focus) ~ label,
.asset-sidebar .c6-star-asset-restrictions .c6-picker > input:focus ~ label,
.asset-sidebar .c6-star-asset-restrictions .c6-picker > input[data-hasvalue="true"] ~ label {
	font-size: 10px;
}



/* Tags */
.asset-sidebar .asset-tags .c6-tags {
	padding-top: 0;
	min-height: 0;
	margin-bottom: 5px;
	border-bottom: 1px solid var(--text-lighter-color);
}

.asset-sidebar .asset-tags .c6-tag {
	background-color: var(--text-lighter-color);
	color: var(--bg-dark-color);
}

.asset-sidebar .asset-tags input {
	color: white;
}

.asset-sidebar .asset-tags .c6-suggestions {
	color: var(--text-color);
}