/* Uploader */
.uploader {
	display: inline-flex;
	vertical-align: top;
	position: relative;
}

.uploader .index-name {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 14px;
	background-color: var(--action-color);
	color: white;
	padding: 0 2px;
}

.uploader .drop-zone {
	border: 1px dotted var(--action-color);

	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
}

.c6-theme-dark .uploader .drop-zone {
	border-color: var(--color-surface-mixed-400);
}

.uploader .drop-zone .upload-instructions {
	position: relative;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);

	font-size: 0.9rem;
	text-align: center;
	color: var(--action-color);
}

.uploader .drop-zone .upload-instructions:before {
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);

	font-size: 2.7rem;
}

.uploader input[type="file"] {
	cursor: pointer;
}

/* File */
.uploader .file {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 100%;
	height: 100%;
	margin-right: 15px;
	border: 1px dotted var(--pending-color);
}

.uploader .file .fileinfo-title > span {
	display: block;
}

/* Itemgroup upload component */
.c6-itemGroup .uploader {
	flex-grow: 1; /* Take up all available space instead of having a large background area */
}

.c6-itemGroup .uploader .drop-zone {
	border: none;
	background-color: var(--action-color);
	transition: background-color ease-in-out .15s;
}
.c6-itemGroup .uploader:hover .drop-zone {
	background-color: var(--action-hl-color);
}
.c6-itemGroup .uploader .drop-zone .upload-instructions {
	color: var(--accent-color);
}
.c6-theme-dark .c6-itemGroup .uploader .drop-zone .upload-instructions {
	color: var(--action-text-color);
}

/* Thumbnail upload component */
.c6-itemGroup.layout-thumbnails .uploader {
	width: 200px;
	height: 200px;
	margin: 2px;
}
.c6-itemGroup.layout-thumbnails.masonry .uploader {
	width: unset;
	height: unset;
	margin: 2px;
	grid-row-end: span 26;
}

/* List item upload component */
.c6-itemGroup:not(.layout-thumbnails) .uploader {
	height: 72px;
	width: 100%;
}

.c6-itemGroup:not(.layout-thumbnails) .uploader .drop-zone .upload-instructions br {
	display: none;
}

.c6-itemGroup:not(.layout-thumbnails) .uploader .upload-instructions:before {
	display: inline-block;
	position: static;
	vertical-align: middle;
	/* margin-right: 10px; */
}

.c6-itemGroup:not(.layout-thumbnails) .uploader .upload-instructions div {
	display: inline-block;
}