/* Note: rjsf-grid editors have 12 tracks to support the common col widths  */

.rjsf-grid > .form-group > fieldset,
.rjsf-grid > .form-group > .grid-object {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	/*grid-gap: 20px;*/
}

.rjsf-grid > .form-group.side-by-side > fieldset,
.rjsf-grid > .form-group.side-by-side > .fieldset {
	grid-auto-flow: column;
}

.rjsf-grid .grid-100 { grid-column: span 12; }
.rjsf-grid .grid-75  { grid-column: span 9;  }
.rjsf-grid .grid-66  { grid-column: span 8;  }
.rjsf-grid .grid-50  { grid-column: span 6;  }
.rjsf-grid .grid-33  { grid-column: span 4;  }
.rjsf-grid .grid-25  { grid-column: span 3;  }

.rjsf-grid .grid-75.grid-right  { grid-column: 10 / span 3;  }
.rjsf-grid .grid-50.grid-right  { grid-column: 7  / span 6;  }
.rjsf-grid .grid-33.grid-right  { grid-column: 9  / span 4;  }
.rjsf-grid .grid-25.grid-right  { grid-column: 10 / span 3;  }

.rjsf-grid .bg {
	background-color: var(--modal-area-color);
}

.rjsf-grid .bg.separator {
	border-top-color: var(--line-color);
	margin-top: 20px;
}

.rjsf-grid fieldset.field > * {
    padding: 0;
}

/* ObjectField title */
.rjsf-grid fieldset.field > legend,
.rjsf-grid .grid-object > h1 {
    margin: 0 20px;
	padding-top: 20px;
	font-size: 16px;
	font-weight: 700;
}

/* ObjectField description */
.rjsf-grid .grid-object > p {
	padding-top: 0;
	margin: 0 20px;
	padding-bottom: 10px;
	font-size: 14px;
}

/* Action bar */
.actionbar-right {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 15;
	height: 2.5rem;
}

.actionbar-right > * {
	margin: 0 !important;
	white-space: nowrap;
}

.actionbar-right small {
	margin-right: 7px !important;
	font-size: 12px;
	line-height: 35px;
}

/* Remove position relative from array items to enable absolute positioning of approve control */
.c6-editor form.rjsf-grid .array-item-list > div {
	position: inherit;
}