.c6-asset-editor {
	--asset-editor-top-height: 110px;
	--asset-editor-height: 85vh;
	--asset-editor-image-actions-height: 50px;

	color: var(--text-lighter-color);
	background-color: var(--bg-darker-color);

	height: var(--asset-editor-height);
	display: grid;
	grid-template-rows: var(--asset-editor-top-height) calc(var(--asset-editor-height) - var(--asset-editor-top-height));
	grid-template-columns: 250px 1fr;
}

.c6-asset-editor .c6-spinner,
.c6-asset-editor .c6-spinner span::after  {
	background-color: var(--bg-darker-color);
}

.c6-asset-editor .c6-filter,
.c6-dialog-paper-dark {
	background-color: var(--bg-darker-color) !important;
}
.c6-asset-editor .c6-filter {
	min-height: 100px;
	grid-column: 1 / -1;
	margin: 0;
}
.c6-asset-editor .c6-filter .metadata {
	font-size: 14px;
}
.c6-asset-editor .c6-filter .metadata > span {
	margin-right: 5px;
}
.c6-asset-editor .c6-filter .asset-title input {
	color: #fff;
}
.c6-asset-editor .c6-filter .asset-title .MuiInput-underline:before {
	border-color: var(--bg-dark-color);
}



.c6-asset-editor .c6-asset {
	grid-column: 1 / -1;
}
.c6-asset-editor .asset-sidebar + .c6-asset {
	grid-column: 2 / -1;
}






/* Main area */
.c6-asset-editor .c6-asset {
	background-color: var(--bg-dark-color);
	display: grid;

	border-bottom: 10px solid var(--bg-darker-color);
	border-right: 10px solid var(--bg-darker-color);
}

.c6-asset-editor .c6-asset .c6-spinner,
.c6-asset-editor .c6-asset .c6-spinner span::after {
	background-color: var(--bg-dark-color);
}

/* No access */
.c6-asset-editor .no-asset-message {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%);
}

.c6-asset-editor .no-asset-message h2:before {
	font-size: 2.5rem;
	vertical-align: sub;
	margin-right: .5rem;
	/* color: var(--attention-color); */
}

/* Actions */
.c6-asset-editor .asset-actions {
	position: relative;
	z-index: 10;
	grid-row: 1 / span 1;
}

.c6-asset-editor .asset-actions h1 {
	display: inline-block;
}

.c6-asset-editor .left-actions {
	text-align: left;
	padding: 8px 0 0 10px;
}

.c6-asset-editor .asset-actions > div {
	display: inline-block;
}

.c6-asset-editor .asset-actions .right-actions {
	text-align: right;
	display: grid;
	grid-template-rows: repeat(2, min-content);
	grid-auto-columns: auto;
	justify-content: end;
	align-items: center;
	grid-gap: 5px;
	padding: 5px;
}
.c6-asset-editor .asset-actions .right-actions p {
	margin: 0;
	font-size: .9rem;
}
.c6-asset-editor .asset-actions .left-actions button,
.c6-asset-editor .asset-actions .right-actions button {
	margin: 0;
	font-size: .9rem;
}

.c6-asset-editor .asset-actions .right-actions button.c6:last-child {
	margin-right: 0;
}


/* Image & Document */
.c6-asset-image {
	grid-template-rows: var(--asset-editor-image-actions-height) calc(var(--asset-editor-height) - var(--asset-editor-top-height) - var(--asset-editor-image-actions-height));
	position: relative;
}

.c6-asset-image .image-wrapper {
	grid-row: 2 / span 1;

	display: grid;
	grid-template-columns: 1fr;
	/* Use this fancy formula instead of max-height 100% because it just won't work for small screens :( */
	grid-template-rows: calc(var(--asset-editor-height) - var(--asset-editor-top-height) - var(--asset-editor-image-actions-height));

	padding: 10px;
}

.c6-asset-image .image-wrapper .c6-img,
.c6-asset-image .image-wrapper .ReactCrop {
	/* max-height: 100%; */
	/* Use this fancy formula instead of max-height 100% because it just won't work for small screens :( */
	max-height: calc(var(--asset-editor-height) - var(--asset-editor-top-height) - var(--asset-editor-image-actions-height) - 20px - 40px);
	max-width: 100%;
	align-self: center;
	justify-self: center;
	display: inline-flex;
}

.c6-asset-image .image-wrapper .c6-img img,
.c6-asset-image .image-wrapper .ReactCrop__image {
	max-width: 100%;
	/* max-height: 100%; */
	/* Use this fancy formula instead of max-height 100% because it just won't work for small screens :( */
	max-height: calc(var(--asset-editor-height) - var(--asset-editor-top-height) - var(--asset-editor-image-actions-height) - 20px - 40px);
}

.c6-asset-image img {
	user-select: none;
	-webkit-user-drag: none;
}

@media screen and (max-width: 1920px), screen and (max-height: 1080px) {
	.c6-asset-image .image-wrapper .c6-img,
	.c6-asset-image .image-wrapper .ReactCrop,
	.c6-asset-image .image-wrapper .c6-img img,
	.c6-asset-image .image-wrapper .ReactCrop__image {
		max-height: calc(var(--asset-editor-height) - var(--asset-editor-top-height) - var(--asset-editor-image-actions-height) - 20px - 100px);
	}
}

/* .c6-asset-image .c6-spinner {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
} */

.c6-asset-image .c6-spinner {
	height: 100px;
    width: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.c6-asset-image .c6-spinner > span {
	height: 100px;
	width: 100px;
	/* position: static; */
}

.c6-asset-image,
.c6-asset-video {
	max-height: 100%;

}

/* Document */
.c6-asset-document {
	max-height: 100%;
}

.c6-asset-document {
	grid-template-rows: auto 1fr;
}

.c6-asset-document > div > p {
	margin: 5px 0;
}

.c6-asset-document .image-wrapper {
	grid-row: 2 / span 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 0 10px 10px;
	position: relative;
	overflow: hidden;
}

.c6-asset-document .document-index-info {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	flex-basis: 50px;
	flex-shrink: 0;
	flex-grow: 0;
	overflow: hidden;
}

.c6-asset-document .document-index-info > h2 {
	margin: 5px 0;
}

.c6-asset-document .document-index-info > button {
	margin: 0 20px;
}

.c6-asset-document .document-index-image {
	display: flex;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;
}

.c6-asset-document .image-wrapper .c6-img img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.c6-asset-document .c6-spinner {
	height: 20%;
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.c6-asset-document .c6-spinner > span {
	height: 100px;
	width: 100px;
	position: static;
}

.c6-asset-document button .c6-text {
	padding-left: 0.75rem;
}

/* Video */
.c6-asset-video {
	grid-template-rows: 50px auto;
}

.c6-asset-video video {
	max-height: calc(100% - 50px);
	max-width: 100%;
	grid-row: 2 / span 1;
}
.c6-asset-video video:only-child {
	max-height: 100%;
	max-width: 100%;
	grid-row: 1 / span 2;
}

.c6-asset-video > div {
	grid-row: 1 / span 1;
}

.c6-asset-video button.c6-text {
	padding-left: 0.75rem;
}

.c6-asset-video-v2 {
	position: relative;
}

.c6-asset-video-v2 > .download {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 5;

	display: flex;
	align-items: flex-start;
}

.c6-asset-video-v2 .download button.c6[disabled] {
	pointer-events: all;
	cursor: not-allowed;
}

.c6-asset-video-v2 .sub-options-wrapper {
	max-height: 230px;
	overflow-y: auto;
}

/* Responsivity */
@media screen and (max-width: 1510px) {
	.c6-asset-editor  button.c6[data-shorttitle]:after {
		content: attr(data-shorttitle);
		display: inline;
		vertical-align: top;
		margin-left: .25rem;
	}

	.c6-asset-editor  button.c6[data-shorttitle] span {
		display: none;
	}
}

@media screen and (max-width: 1270px) {
	.c6-asset-editor {
		--asset-editor-image-actions-height: 80px;
	}

	.c6-asset-editor .asset-actions h1 {
		font-size: 1.2rem;
		margin-left: 10px;
		margin-right: 10px;
	}
	.c6-asset-editor .asset-actions > * {
		width: unset !important;
	}
	.c6-asset-editor .asset-actions .right-actions {
		flex-grow: 1;
	}

	.c6-asset-editor .asset-actions .right-actions p {
		font-size: .8rem;
	}
	.c6-asset-editor .asset-actions .left-actions button,
	.c6-asset-editor .asset-actions .right-actions button {
		font-size: .8rem;
		padding: 0 0.5rem 0 0.25rem;
		height: 2rem;
		line-height: 2rem;
	}
	.c6-asset-editor .asset-actions .left-actions button:before,
	.c6-asset-editor .asset-actions .right-actions button:before {
		line-height: 2rem;
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 930px) {
	.c6-asset-editor .asset-actions .left-actions button:before,
	.c6-asset-editor .asset-actions .right-actions button:before {
		display: none;
	}
}

.c6-asset-editor .c6-asset-image .c6-img.has-failed {
	background: none;
}