.c6-metadata-preview {
	position: relative;
	padding: 0;
	font-size: .85rem;
	background-color: var(--bar-color);
	color: var(--accent-color);
}

.c6-metadata-preview .left {
	display: flex;
}

.c6-metadata-preview .left > * {
	flex: 1;
}

.c6-metadata-preview .thumbnail {
	max-width: 200px;
	background-color: var(--bar-color);
	background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, #263238 5px, #263238 10px);
}

.c6-metadata-preview img {
	object-fit: inherit;
}

.c6-metadata-preview .left > :last-child {
	margin-left: 10px;
}

.c6-metadata-preview h1 {
	margin-top: 5px;
	margin-bottom: -5px;
	display: inline-block;
}

.c6-metadata-preview dl {
	display: grid;
	grid-template-columns: 85px 1fr;
	grid-gap: 5px 10px;

	margin: 5px 10px 10px 10px;
}

.c6-metadata-preview dd {
	text-align: left;
	margin: 0;
}

.c6-metadata-preview section {
	margin: 5px 0 0 0;
}

.c6-metadata-preview ul {
	display: inline;
	padding: 0;
	margin: 0;
}

.c6-metadata-preview li {
	display: inline;
}

.c6-metadata-preview li:after {
	content: ",";
	margin-right: .25em;
}

.c6-metadata-preview li:last-child:after {
	content: ".";
}

.c6-metadata-preview ul.and li:nth-last-child(2):after {
	content: " and ";
	margin-right: 0;
}

.c6-metadata-preview .c6-label {
	/* vertical-align: super;
	margin-left: 5px; */
	position: absolute;
	top: 10px;
    left: 10px;
}

.c6-metadata-preview .c6-link {
	padding: 0;
}

.c6-metadata-preview .image-qc-link {
	position: absolute;
    top: calc(112px / 2);
    left: calc(200px / 2);
    transform: translate(-50%, -50%);
    font-size: 5rem;
    line-height: 1;
	pointer-events: none;
}

.c6-metadata-preview.has-image-qc-link .c6-preview {
	cursor: pointer;
}