/* Item groups */
.c6-itemGroup {
	margin-top: 20px;
}

.c6-itemGroup:first-child {
	margin-top: 0;
}

.c6-itemGroup .items {
	margin-bottom: 20px;
}

.c6-app.c6-layout-grid .c6-itemGroup .items:last-child {
	margin-bottom: 0;
}

.c6-itemGroup:not(.hasTitle):first-child:last-child  {
	margin-bottom: 0;
}

.c6-itemGroup > h1 {
	width: 100%;
	margin-top: 1.75rem;
	margin-bottom: 10px;
	font-size: 1rem;
	user-select: none;
	cursor: default;
}

.c6-itemGroup > h1:first-child {
	margin-top: 0;
}

.c6-itemGroup.collapsible > h1 {
	cursor: pointer;
}

.c6-itemGroup > h1 span span {
	color: var(--text-light-color);
}

.c6-itemGroup > h1 .c6-link {
	color: var(--action-color);
}

.c6-itemGroup > h1 .c6-link:hover {
	color: var(--accent-color);
}

/* Horizontal item group */
.c6-itemGroup-horizontal > h1 {
	margin-left: 20px;
}

.c6-itemGroup-horizontal .c6-item {
	min-height: 100px;
	border-bottom: 0;
	margin-right: 1px;
	width: 200px;
}

.c6-itemGroup-horizontal .c6-item:first-child {
	margin-left: 20px;
}

.c6-itemGroup-horizontal .c6-item:last-child {
	margin-right: 20px;
}

.c6-itemGroup-horizontal .c6-item:hover {
	box-shadow: none;
}

/* Horizontal scroll */
.scroll-horizontal {
	overflow-x: auto;
	/* scroll-snap-type: x mandatory; */
}

/* .scroll-horizontal .c6-item {
	scroll-snap-align: start;
} */

.scroll-horizontal > div {
	display: flex;
	width: max-content;
}

.scroll-horizontal::-webkit-scrollbar {
	width: auto;
	height: 10px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
	background: var(--action-color);
}

.scroll-horizontal::-webkit-scrollbar-thumb:hover {
	background: var(--action-hl-color);
}

.scroll-horizontal::-webkit-scrollbar-track {
	margin-left: 20px;
	margin-right: 20px;
}

/* Item group with thumbnail layout */
.c6-itemGroup.layout-thumbnails .items {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: var(--content-bg-color);
	padding: 5px;
}

.c6-theme-dark .c6-itemGroup.layout-thumbnails .items {
	background-color: var(--modal-area-light-color);
}

.c6-itemGroup.layout-thumbnails .items:after {
    content: "";
    display: block;
    flex-grow: 1; /* Create an invisible last item taking upp all the rest of the space to handle the last row orphans */
}

.c6-itemGroup.layout-thumbnails > h1 {
	background-color: var(--bg-color);
}

.c6-itemGroup.layout-thumbnails .c6-item {
	margin: 2px;
	border-bottom: 0;
}

.c6-itemGroup.layout-thumbnails .c6-item:hover {
	box-shadow: none;
}

.c6-itemGroup.layout-thumbnails .c6-item:hover img {
	filter: sepia(100%) hue-rotate(135deg) saturate(3) brightness(75%);
}

.c6-itemGroup.layout-thumbnails .c6-item.sel img,
.c6-itemGroup.layout-thumbnails .c6-item.sel:hover img {
	filter: sepia(100%) hue-rotate(37deg) saturate(1) brightness(100%);
}

.c6-itemGroup.layout-thumbnails .c6-content {
	display: none;
}


.c6-itemGroup.layout-thumbnails .c6-item:hover .c6-content:not(:empty) {
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	color: var(--accent-color);
	background-color: var(--action-color);
	word-break: break-word;
	width: 100%;
	z-index: 1;
}

.c6-itemGroup.layout-thumbnails .c6-item:hover .c6-content > p {
	color: var(--accent-color);
}

.c6-itemGroup.layout-thumbnails .c6-item:hover .c6-content h1 {
	/* Multi-line CSS ellipsis (in everything except Firefox)! */
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* Item group with thumbnail masonry layout */
.c6-itemGroup.layout-thumbnails.masonry .items {
	display: grid;
	row-gap: 3px;
	column-gap: 5px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-auto-rows: 5px;
	padding: 5px;
}

.c6-itemGroup.layout-thumbnails.masonry .c6-preview {
	width: 100% !important;
	height: 100% !important;
	min-width: unset;
}
.c6-itemGroup.layout-thumbnails.masonry .c6-preview > img {
	width: 100%;
	height: 100%;
}

.c6-itemGroup.layout-thumbnails.masonry .c6-contentItem {
	min-height: unset;
}

.c6-itemGroup.layout-thumbnails.masonry .preview-icon {
	width: 100%;
	height: 100%;
}

/* Items */
.c6-item {
	background: var(--area-lightest-color);
	position: relative;
	overflow: hidden; /*visible; /* To have list item actions be visible below the content block */
	/*margin-bottom: 1px;*/
	border-bottom: 1px solid var(--bg-color);
}

.c6-theme-dark .c6-item {
    background: var(--area-lighter-color);
}

.c6-item:not(.c6-contentItem):hover,
.c6-item:not(.c6-contentItem).sel {
	box-shadow: inset 3px 0 0 rgb(0, 188, 212);
}

.is-dragging-item .c6-item:hover {
	box-shadow: none;
}

.c6-item.selectable {
	cursor: pointer;
}

.c6-item .flex {
	min-height: 4rem;
}

.c6-item.sel {
	color: var(--action-color);
	background-color: var(--content-hl-color);
}

.c6-item.sel p {
	color: inherit;
}

.c6-item:not(.sel).status-unread:before {
	content: "\25CF";
	color: var(--action-color);
	position: absolute;
	left: 10px;
	line-height: 70px;
}

/* Used to mark anchored items */
.c6-item .flex:not(.sel) {
	transition: background-color 3s ease-out; /* Slowly fade away the backgroud color when the item hash is removed */
}

.c6-item .flex.sel {
	background-color: var(--content-hl-color);
}
.c6-item .flex.sel:before {
	content: "";
	display: flex;
	width: 3px;
	height: 100%;
	background-color: var(--action-color);
	position: absolute;
	z-index: 1;
}

/* Sub items */
.c6-subitem:before  {
	font-family: "icomoon";
	content: "\e20c";
	margin-left: 15px;
	color: var(--line-color);
	align-self: center;
	position: absolute;
}

.c6-subitem .c6-content:first-child > * {
	margin-left: 30px;
}

/* Small items */
.c6-item.small .flex {
	min-height: 2rem;
}

/* Secondary content */
.c6-secondary {
	background-color: var(--content-secondary-color);
}

.c6-item:hover .c6-secondary {
	box-shadow: inset 3px 0 0 rgb(0, 188, 212);
}

/* List item previews (cover = 5 items * 40px) */
.cover-previews {
	max-width: 200px;
	padding: 0 !important;
	overflow: hidden;
	display: flex;
	background: #fff;
	/*align-items: center;*/
}

.cover-previews img {
	display: inline-block;
}

/* Align drag handles and stuff (on old c6-items) */
.c6-item:not(.c6-contentItem) > * {
	display: flex;
    flex-wrap: wrap;
}

.c6-item:not(.c6-contentItem) > * > * {
	width: 100%;
}