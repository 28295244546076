@font-face {
	font-family: 'Roboto';
	font-weight: bold;
	font-style: normal;

	src: url('../../client/fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: normal;
	font-style: normal;

	src: url('../../client/fonts/Roboto-Light.woff2') format('woff2');
}

body:before {
	content: "This is an old web browser. Please use a more recent version of Chrome (v63+), Firefox (v58+), Microsoft Edge (v16+) or Safari (v11.1+) instead. - Thank you and have a nice day!";
	display: block;
	max-width: 300px;
	margin: 0 auto;
	text-align: center;
	background: #f44336;
	color: white;
	margin-top: 20px;
	padding: 10px;
	font-family: Roboto, Verdana, Helvetica, Arial, Sans-serif;
}

@supports (display: grid) { /* supports overscroll-behavior = Chrome 63 which is the version that got Promise.finally */
	body:before {
		display: none;
	}
}

html {
	box-sizing: border-box;

	font-family: var(--default-font);
	font-size: 14px;
	font-weight: 300;

	color: var(--text-color);
	background-color: var(--bg-color);
}

*,
*:before,
*:after {
	box-sizing: inherit;
}
img {
	box-sizing: content-box;
}
*:focus {
	outline: none;
}

/*input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	background-color: inherit;
}*/

mark,
::selection {
	color: var(--accent-color);
	background-color: var(--accent-bg-color);
}

/* BUG: Can't override autofill color. Maybe stop auticomplete with form autocomplete=off instead?
/* See: https://bugs.chromium.org/p/chromium/issues/detail?id=46543 */
/*input:-webkit-autofill {
	background-color: inherit !important;
}*/




/* TODO: MOVE THESE TO BETTER LOCATION PLZ */
html,
body,
#root,
.c6-core,
.c6-core > div:not(.alert-container):not(.alert-network) {
	overflow: hidden;

	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

/* Displays build version in the bottom right corner */
html.product-comet:not([data-version="#version#"]):not(.hide-version)::after {
	content: attr(data-version);
	display: block;
	position: fixed;
	bottom: 5px;
	right: 10px;
	height: 20px;
	z-index: 20;
	color: var(--accent-color);
}

body {
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 1.5rem;
	font-weight: normal;
}

[disabled],
.disabled {
	pointer-events: none;

	opacity: .45;
}

.dimmed {
	opacity: .3;
}

.c6-mui[disabled],
.c6-mui [disabled] {
	opacity: 1;
}

/* Prevents disabled date pickers from being too much grayed out */
.muiDateField > div > input[type='text']:disabled {
	opacity: 1 !important;
}

[readonly] {
	color: var(--text-lighter-color) !important;
	cursor: text !important;
}

.hide {
	display: none;
}

/*main {
	transition: opacity .2s ease-in-out;
	transition-delay: .5s;
}

.c6-loading main {
	opacity: .65;
}*/

/* Bottom bar */
.c6-bottom {
	position: fixed;
	z-index: 20;
	bottom: 0;

	width: 100%;
	height: 2.5rem;
	margin-left: -20px;
	padding: 0 20px;

	user-select: none;
	pointer-events: none; /* Don't block auto scrolling during DnD in supported browsers */

	line-height: 2.5rem;

	color: #fff;
	background-color: var(--bar-transparent-color);

	-webkit-backdrop-filter: blur(4px);
}

/* .c6-app.c6-layout-grid > .c6-bottom {
	margin-left: 0;
} */

/* Flex */
.flex {
	display: flex;
}

.flex-inline {
	display: inline-flex;
}

.flex-v-align-content {
	align-items: center;
}

.flex-h-align-content {
	justify-content: center;
}

.flex-space-between {
	justify-content: space-between;
}

.flex-1-1 > *:not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 50%;
}

.flex-1-1-1 > *:not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 33.3%;
}

.flex-1-1-1-1 > *:not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 25%;
}

.flex-dyn > *:last-child {
	flex: 1;
	max-width: unset !important;
}

.flex-dyn-1 > *:not(.c6-actions):not(.c6-preview):not(.c6-icon-container):not(.c6-icon):not(.c6-content-actions) {
	flex: 1; /* Needed so the content part doesn't render below the asset preview image */

	width: 100%;
}

.flex-2-1 > *:first-child:not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 66%;
}

.flex-2-1 > *:nth-child(2):not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 34%;
	margin-left: 20px;
}

/*.flex-2-1-1 > *:first-child:not(.c6-actions) {*/
.flex-2-1-1 > *:first-child {
	width: 50%;
}

/*.flex-2-1-1 > *:not(:first-child):not(.c6-actions) {*/
.flex-2-1-1 > *:not(:first-child) {
	width: 25%;
	margin-left: 20px;
}

.flex-1-3 > *:first-child:not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 25%;
}

.flex-1-3 > *:not(:first-child):not(.c6-actions):not(.c6-icon-container):not(.c6-icon) {
	width: 75%;
	margin-left: 20px;
}

/*.flex-2-1-1 > *:first-child:not(.c6-actions) {*/
.flex-1-2-2 > *:first-child {
	width: 20%;
}

/*.flex-2-1-1 > *:not(:first-child):not(.c6-actions) {*/
.flex-1-2-2 > *:not(:first-child) {
	width: 40%;
	margin-left: 20px;
}

.flex-1-1-2 > *:last-child {
	width: 50%;
}

.flex-1-1-2 > *:not(:last-child) {
	width: 25%;
}

.flex-wrap {
	flex-wrap: wrap;
}

/* Positioning */

.left {
	/*width: 100%;*/
	flex: 2; /* HACK!!!: I feel dirty */

	text-align: left;
}

.right {
	text-align: right;
	/*width: 100%;*/
}

.center {
	display: flex;

	cursor: default;
	user-select: none;
}

.center > div {
	margin: auto;
}

.visually-hidden {
	position: absolute;

	overflow: hidden;
	clip: rect(0 0 0 0);

	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;

	border: 0;
}

/*.align-center {
	text-align: center;
}

.align-center time {
	min-width: 6em;
	display: inline-block;
	text-align: right;
}

.align-center time + time {
	text-align: left;
}*/

/* Group */
.group > * {
	margin-right: 1rem;
}

.group > *:last-child {
	margin-right: 0;
}

/* Connections */
.connected {
	margin-right: 10px;
	margin-left: 5px;

	color: var(--accent-bg-color);
}

.connected:before {
	margin-right: 5px;

	color: var(--accent-bg-color);
}

/* Modal Base */
.c6-modal .c6-actionbar {
	z-index: 10;
	top: 0;

	width: 100%;

	background-color: var(--bar-color);
}

.c6-modal-body {
	padding: 0 !important;
	background-color: var(--modal-bg-color);
	background-image: none !important;
}

.c6-theme-dark .c6-modal-body {
	outline: 1px solid var(--modal-area-light-color);
}

.c6-modal-body.c6-modal-body-dark {
	background-color: var(--bg-darker-color);
}
.c6-modal-body.c6-modal-body-max-height-none {
	max-height: none !important;
}

.c6-modal-body .c6-app > .scroll {
	width: 100%;
}

.c6-modal-body > .c6-editor {
	width: 100%;
	min-width: inherit;
	margin-top: 0;
	padding-top: 2.5rem;
}

.c6-modal-overlay,
.c6-modal .MuiBackdrop-root {
	/*transition: backdrop-filter ease-in 2s;*/
	-webkit-backdrop-filter: blur(2px) grayscale(.75);
}

.c6-theme-dark .c6-modal-overlay,
.c6-theme-dark .c6-modal .MuiBackdrop-root {
	background-color: rgba(0, 0, 0, 0.8);
}

.card-1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card-2 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-4 {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* Action bar */
.c6-actionbar {
	position: absolute;

	transition: background-color ease-in-out .2s;
	text-transform: uppercase;
}

.c6-actionbar button {
	transition: color ease-in-out .2s;
}

.c6-actionbar button.c6-text[class*=' icon-'] > span {
	vertical-align: super;
}

.c6-actionbar button[disabled] {
	filter: grayscale(1);
}

/* Colors */
.c6-color-light {
	color: var(--text-light-color);
}
.c6-color-lighter {
	color: var(--text-lighter-color);
}

.fg-green,
.c6-text-approved {
	color: var(--ok-color) !important;
}

.fg-lightgreen {
	color: var(--success-color) !important;
}

.fg-red,
.c6-text-unapproved,
.c6-text-undefined {
	color: var(--error-color) !important;
}

.fg-lightred {
	color: var(--attention-color) !important;
}

.fg-blue {
	color: var(--action-color) !important;
}

/* Status */
.c6-status-undefined {
	background-color: var(--text-light-color) !important;
}

.c6-status-disabled {
	background-color: var(--text-lighter-color) !important;
}

.c6-status-headsup,
.c6-status-new,
.c6-status-exported,
.c6-status-delivering,
.c6-status-onitsway,
.c6-status-unhandled,
.c6-status-queued,
.c6-status-waiting,
.c6-status-ignored,
.c6-status-warning {
	background-color: var(--pending-color) !important;
}

.c6-status-available,
.c6-status-true,
.c6-status-active,
.c6-status-confirmed,
.c6-status-upcoming,
.c6-status-approved,
.c6-status-published,
.c6-status-created,
.c6-status-complete,
.c6-status-delivered,
.c6-status-ok {
	background-color: var(--ok-color) !important;
}

.c6-status-attention,
.c6-status-false,
.c6-status-inactive,
.c6-status-unconfirmed,
.c6-status-expired,
.c6-status-unapproved,
.c6-status-canceled,
.c6-status-cancelled,
.c6-status-blocked,
.c6-status-unpublished,
.c6-status-failed {
	background-color: var(--error-color) !important;
}

/* bold/italics */
.c6-text-bold {
	font-weight: bold;
}
.c6-text-italic {
	font-style: italic;
}

/* Actions */
.c6-action {
	margin: 0 1px;
	padding: 2px 5px;

	cursor: pointer;
	white-space: nowrap;
	text-decoration: none;

	font-family: inherit;
	font-size: inherit;

	color: #fff;
	/*-webkit-appearance: none;*/
	border: 0;
	background-color: var(--action-color);
}

.c6-action:hover {
	background-color: var(--action-hl-color);
}

/* Link */
.c6-link {
	padding: 3px 1px;

	text-decoration: none;

	color: var(--action-color);
	border-bottom: 1px dotted var(--action-color);

	/* transition: border-bottom-color .15s ease-in-out, background-color .15s ease-in-out, color .15s ease-in-out; */
}

.c6-link:hover {
	cursor: pointer;

	color: var(--accent-color);
	border-bottom-color: transparent;
	background-color: var(--action-hl-color);
}

/* Presentation widget: Number Description */
.numberDescription {
	display: inline-block;

	margin-right: 1.5rem;

	text-align: center;

	font-size: 1.5rem;
}

.numberDescription small {
	display: block;

	text-transform: uppercase;

	font-size: .85rem;

	color: var(--text-light-color);
}

/* Metadata */
.c6-metadata {
	padding: 20px;

	/* border-bottom: 1px solid var(--line-color); */
	box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
	background-color: var(--modal-area-color);
}

.c6-metadata.metadata-bar {
	margin-top: -20px;
	padding: 10px;

	line-height: 24px;
}


.direct-link-container {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: #606768;
}

.direct-link-container .c6-app.c6-editor {
	position: relative;
	z-index: 50;

	width: 100%;

	align-items: center;
	justify-content: center;
}

.direct-link-container .c6-app.c6-editor .c6-editor {
	position: relative;

	width: 100%;
	max-width: 768px;
	padding-top: 5rem;
}

.direct-link-container .c6-app.c6-editor .c6-actionbar {
	top: 0;

	width: 100%;

	background: #324149;
}

.direct-link-container .c6-app.c6-editor .c6-actionbar button.c6.icon-close,
.direct-link-container .c6-app.c6-editor .c6-actionbar button.c6.icon-settings {
	display: none;
}

/* Icons */
.c6-icon {
	display: inline-block;

	width: 25px;
	min-width: 25px; /* For Safari */
	padding-left: 10px;

	align-self: center;
}

.c6-icon-container {
	display: inline-block;

	width: 40px;
	min-width: 40px; /* For Safari */
	padding-left: 25px;

	align-self: center;
}

.large-icon:before {
	display: inline-block;
	/*line-height: 1.2rem !important;*/

	padding-right: 5px;

	font-size: 1.5rem;
}

/* Periods */
.c6-period {
	display: inline-block;

	margin-right: 1em;

	vertical-align: middle;
}

/* TODO: REMOVE WHEN WE ONLY USE Period Controls */
.c6-content time + time:before {
	margin-right: .5rem;
	margin-left: .5rem;

	content: "\25B6"; /* 25B6 = play arrow right */

	font-size: .75rem;

	color: var(--label-color);
}

/* Links */
.titleLink {
	margin-right: -3px;
	margin-left: -3px;
	padding: 3px;

	cursor: pointer;
	text-decoration: none;

	color: var(--text-color);
	border-bottom: 1px dotted var(--action-color);
}

.titleLink:hover {
	text-decoration: none;

	color: var(--accent-color);
	border-bottom-color: transparent;
	background-color: var(--action-hl-color);
}


/* infinite scroller */
.infinite-loader {
	display: flex;
	clear: both;

	height: 50px;

	align-items: center;
	justify-content: center;
}

/* Welcome screen */
.c6-welcome {
	padding-top: 36px;
}
.c6-theme-dark .c6-welcome {
	color: var(--text-light-color);
}

.c6-welcome.scroll > * {
	padding-bottom: 0;
	height: calc(100vh - 72px);
	margin-right: 5px;
}

.c6-welcome.scroll > *::-webkit-scrollbar-track {
	margin-bottom: 20px;
}

.c6-welcome svg {
	margin-left: 20px;
	width: 72px;
	height: 72px;
}

.c6-welcome svg + h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 62px;
	color: var(--area-color);
	position: relative;
	left: -4px;
}
.c6-theme-dark .c6-welcome svg + h1 {
	color: var(--text-color);
}

.c6-welcome svg ~ * {
	margin-left: 20px;
	margin-right: 20px;
}

.c6-welcome mark {
	background: none;
	color: inherit;
	border-bottom: 4px solid var(--attention-color);
}


/* When we have small screen widths we want to scroll the whole page for better UX */
@media screen and (max-width: 450px) {
	.c6-welcome {
		overflow-y: initial;
		height: auto !important;
	}
}

@media screen and (min-width: 500px) {
	.c6-welcome svg {
		float: left;
	}

	.c6-welcome svg ~ * {
		margin-left: 110px;
		margin-right: 110px;
	}
}

/* Network connection indicator */
.alert-network {
	position: fixed;
	z-index: 1501;
	color: var(--attention-fg-color);
	background-color: var(--attention-color);
	padding: 10px 50px;

	top: 70px;
	left: -65px;
	transform: rotate(-45deg);
	box-shadow: 0 0 3px rgba(0, 0, 0, .3);
	user-select: none;
	pointer-events: none;
}


#c6-api-dialog-root {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	display: none;
}
#c6-api-dialog-root.visible {
	display: block;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.3); 
}

#c6-api-dialog-root .dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	pointer-events: all;

	padding: 20px;
}

#c6-api-dialog-root .dialog img {
	max-width: 300px;
	max-height: 300px;
}

#c6-api-dialog-root .dialog .c6-button {
	display: block;
	width: 100%;
	min-width: 100px;
	margin: 20px 0 0;
}