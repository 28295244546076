button.c6,
.c6-button {
	border: 0;
	color: var(--action-text-color);
	background-color: var(--action-color);
	margin: 0 4px;
	padding: 0;
	font-family: inherit;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color ease-in-out .15s, box-shadow ease-in-out .15s;
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	overflow: hidden;
	user-select: none;
	vertical-align: top;
	border-radius: var(--comet-border-radius);
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	button.c6:hover,
	.c6-button:hover {
		background-color: var(--action-hl-color);
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
	}
}

button.c6:active,
.c6-button:active {
	background-color: #00ACC1;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25) inset;
}

button.c6:first-child,
.c6-button:first-child {
	margin-left: 0;
}

button.c6:last-child,
.c6-button:last-child {
	margin-right: 0;
}
button.c6-text,
.c6-button.c6-text {
	padding: 0 .75rem;
	text-transform: inherit;
}

/* Button Icons */
button.c6[class^="icon-"]:before,
button.c6[class*=" icon-"]:before,
.c6-button.c6[class^="icon-"]:before,
.c6-button.c6[class*=" icon-"]:before,
.c6-buttongroup.has-sub-options .sub-options-toggle:before {
	line-height: 2.5rem; /* inherit !important; */
	font-size: 1.75rem;
}

button.c6.icon-smaller[class^="icon-"]:before,
button.c6.icon-smaller[class*=" icon-"]:before,
.c6-button.c6.icon-smaller[class^="icon-"]:before,
.c6-button.c6.icon-smaller[class*=" icon-"]:before {
	font-size: 1.5rem;
}

button.c6:not(.c6-text)[class^="icon-"] > span,
button.c6:not(.c6-text)[class*=" icon-"] > span,
.c6-button:not(.c6-text)[class^="icon-"] > span,
.c6-button:not(.c6-text)[class*=" icon-"] > span {
	display: none;
}

.c6-buttongroup.has-sub-options {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-left: 5px; 
	position: relative;
	background-color: var(--action-color);
    border-radius: var(--comet-border-radius);
}
button.c6.has-sub-options {
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 2.5rem;
}
.c6-buttongroup.has-sub-options .sub-options-toggle {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	color: #fff;
	cursor: pointer;
	padding: 0 5px;
}
.c6-buttongroup.has-sub-options .sub-options-wrapper {
	display: flex;
    flex-direction: column;
}
button.c6.sub-option {
	text-align: left;
	padding: 0 2.5rem 0 0.75rem;
	position: relative;
	flex-shrink: 0;
	border-radius: 0;
}
button.c6.sub-option .c6-spinner {
	right: 0;
	left: unset;
}
button.c6.sub-option .c6-spinner,
button.c6.sub-option .c6-spinner > span:after {
	background-color: var(--action-color);
}
button.c6.sub-option:hover .c6-spinner,
button.c6.sub-option:hover .c6-spinner > span:after {
	background-color: var(--action-hl-color);
}
button.c6.sub-option:last-child {
	border-radius: 0 0 var(--comet-border-radius) var(--comet-border-radius);
}

/* IconTextButton */
button.c6-text[class^="icon-"],
button.c6-text[class*=" icon-"],
.c6-button.c6-text[class^="icon-"],
.c6-button.c6-text[class*=" icon-"] {
	line-height: 2.5rem; /* inherit !important; */
	padding-left: .25rem;
}

button.c6-text[class^="icon-"] > span,
button.c6-text[class*=" icon-"] > span,
.c6-button.c6-text[class^="icon-"] > span,
.c6-button.c6-text[class*=" icon-"] > span {
	vertical-align: top; /*super;*/
    /*text-transform: uppercase;*/
	margin-left: .25rem;
}


button.c6.noBackground,
.c6-button.noBackground {
	background-color: transparent;
	color: var(--action-color);
	box-shadow: none;
}

button.c6.dangerous,
.c6-button.dangerous {
	background-color: var(--error-color);
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	button.c6.noBackground:hover,
	.c6-button.noBackground:hover {
		color: var(--action-hl-color);
	}

	button.c6.dangerous:hover,
	.c6-button.dangerous:hover {
		background-color: var(--attention-hl-color);
	}
}

button.c6.dangerous:active,
.c6-button.dangerous:active {
	background-color: #ec3224;
}

button.c6.noBackground.dangerous {
	background-color: transparent;
	color: var(--attention-color);
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	button.c6.noBackground.dangerous:hover {
		color: var(--attention-hl-text-color);
	}
}

/* Button group */
.c6-buttongroup {
	transition: box-shadow ease-in-out .15s;
	display: inline-block;
}

.c6-buttongroup:active button,
.c6-buttongroup button:active {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25) inset !important;
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	.c6-buttongroup:not(.noBackground):hover {
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
	}
}

.c6-buttongroup:active {
	box-shadow: none;
}

@media (hover), not all and (-moz-touch-enabled: 1) {
	.c6-buttongroup button:hover {
		box-shadow: none;
	}
}

.c6-buttongroup button {
	margin-left: 0;
	margin-right: 0;
}



button.MuiButton-root {
	display: inline-flex;
	align-items: center;
	line-height: 1rem;
}