.c6-product-name {
	color: var(--accent-color) !important;
	position: absolute;
	padding: 10px;
	top: 1px;
	left: 25px;
}

.c6-product-name > .product {
	margin-left: 5px;
}

.c6-module-chooser {
	grid-column: 1;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.c6-module-chooser .highlighted-primary-text,
.c6-product-name .highlighted-primary-text {
	padding: 3px;
	background-color: var(--attention-color);
	border-radius: var(--comet-border-radius);
	color: var(--accent-color);
}

.c6-drawer-container {
	height: calc(100% - 36px) !important;
	margin-top: 36px;
	background: var(--action-color) !important;
	overflow-x: hidden !important;
	overflow-y: auto !important;
}

.c6-theme-dark .c6-drawer-container {
	background: var(--area-color) !important;
}

.c6-drawer-item > div {
	border-bottom: 1px solid var(--action-demihl-color);
	padding-left: 0 !important;
}

.c6-drawer-item {
	padding: 0;
}

.c6-drawer-item span {
	color: var(--accent-color);
	font-family: var(--default-font);
}

.c6-drawer-item a {
	color: var(--accent-color);
	font-family: var(--default-font);
	text-decoration: none;
	display: inline-block;
	height: 100%;
	width: 100%;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding: 12px 16px;
	min-width: 250px;
}

.c6-drawer-item a:before {
	color: var(--accent-color);
	font-family: "icomoon";
	margin-right: 1rem;
	font-size: 1.75rem;
	text-decoration: none;
}

.c6-drawer-item svg {
	color: var(--accent-color) !important;
}

.c6-drawer-item.active-module a {
	color: var(--action-color);
}

.c6-drawer-item.active-module a:before {
	color: var(--action-color);
}

.c6-drawer-item.active-module {
	background-color: var(--accent-color);
}