.c6-tagsfield {
	/* margin-top: 16px !important; */
}

.c6-tagsfield label {
    font-size: 12px;
	/* color: rgba(0, 0, 0, 0.3); */
	color: var(--text-lighter-color);
	/*transition: color .15s ease-in-out;*/
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

	position: relative;
	top: 12px;
}

.c6-tagsfield.is-focused label {
	color: rgb(0,188, 212);
}

/* =============== */
/*    CONTAINER    */
/* =============== */
.c6-tags {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	min-height: 38px;
	/*width: calc(100% - 40px);*/
	padding: 15px 4px 0 0;
	margin: 0;

	border-bottom: 1px solid var(--line-color);

	font-family: var(--default-font);
	cursor: text;

	transition: border-bottom .15s ease-in-out;
}
.c6-tags:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -1px;
	left: 50%;
	background-color: transparent;
	height: 2px;
	max-width: 0;
	width: 0;
	/*transition: max-width .15s ease-in-out, left .15s ease-in-out;*/
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

/*.c6-tagsfield.is-focused .c6-tags {
	border-bottom: 2px solid var(--action-color);
}*/

.c6-tagsfield.is-focused .c6-tags:after {
	background-color: rgb(0, 188, 212);
	max-width: 100%;
	width: 100%;
	left: 0;
}

.c6-tags.read-only,
.c6-tags .read-only {
	cursor: default;
}


/* =============== */
/*    TEXT INPUT   */
/* =============== */
.c6-tags input {
	align-self: flex-end;
	flex-grow: 1;

	font-family: var(--default-font);
	font-size: 14px;

	margin: 0;
	height: 28px;
	line-height: 28px;
	max-width: 70px;
	border: none;

	background-color: transparent;
}






/* =============== */
/*       TAG       */
/* =============== */
.c6-tag {
	display: inline-block;

	border-radius: 13px;
	background-color: #dadada;
	/*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16),
				0 2px 4px rgba(0, 0, 0, 0.23);*/

	padding: 3px 8px;
	margin: 2px 6px 2px 0;
	height: 24px;
	line-height: 18px;

	cursor: move;

	-webkit-user-drag: element;
	user-select: none;

	transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.c6-theme-dark .c6-tag {
	background-color: var(--content-secondary-color);
}

.c6-tag.allow-reorder:hover,
.c6-tag:not(.read-only):hover {
	color: var(--accent-color);
	background-color: var(--action-color);
}

.c6-tag.allow-reorder {
	cursor: move !important;
}

.c6-tag > button {
	font-family: var(--default-font);
	font-size: 14px;
	font-weight: bold;

	border: none;
	background: none;

	padding: 0 2px;
	margin: 0 0 0 8px;

	cursor: pointer;
	transition: color .15s ease-in-out;
}

.c6-tag:hover > button {
	color: var(--accent-color);
}

.c6-tag.keyword-tag {
	/*background-color: #E0E0E0;*/
}
.c6-tag.genre-tag {
	background-color: #B0EEB0;
}
.c6-tag.location-tag {
	background-color: #E2BBA1;
}
.c6-tag.mood-tag {
	background-color: #BC99C6;
}