.c6-databite {
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
}
.c6-databite:before {
	color: var(--label-color);
	/*vertical-align: text-bottom;*/
	margin-left: -2px;
}