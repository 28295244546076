.asset {
	display: inline-flex;
	justify-content: center;
	position: relative;
	cursor: pointer;

	border: 2px solid var(--content-secondary-border-color);
	background-color: var(--content-secondary-color);
	box-sizing: content-box;
}

.c6-theme-dark .asset {
	border-color: var(--content-hl-light-color);
    background-color: var(--content-light-color);
}

.asset .index-name {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	font-size: 1.1em;
	background-color: var(--action-color);
	color: white;
	padding: 0 2px;
}

.asset .LazyLoad {
	align-self: center;
	height: 100%;
	width: 100%;
	position: relative;
}

.asset .c6-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
}

.asset .LazyLoad,
.asset .LazyLoad * {
	max-width: 100%;
	max-height: 100%;
}

.asset img {
	object-fit: contain;
	cursor: pointer;
}

.asset > button {
	display: block;

	border-radius: 50%;
	background-color: var(--attention-color);
	color: var(--attention-fg-color) !important;
	opacity: 0;
	transition: opacity .3s ease;

	position: absolute;
	z-index: 10;
	top: -0.75rem;
	right: -0.75rem;
	height: 1.6rem;
	min-width: 1.6rem;
	line-height: 1.6rem;
}
.asset > button:before {
	font-size: 1.2rem !important;
	line-height: 1.6rem !important;
}

.asset:hover > button {
	opacity: 1;
}

.asset:hover > .asset-text {
	opacity: 1;
}

.asset .c6-spinner {
	background-color: #F5FBFD;
}
.asset .c6-spinner > span:after {
	background: #F5FBFD;
}

.asset .c6-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
}
.asset .c6-spinner > span {
	background: linear-gradient(to right, var(--action-color) 10%, rgba(255, 255, 255, 0) 50%);
}
.asset .c6-spinner > span:before {
	background: var(--action-color);
}
.asset .c6-spinner > span:after {
	background: white;
}

.asset .asset-text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;

	font-size: 1em;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px;

	opacity: 0;
	transition: opacity .3s ease;
	user-select: none;
	cursor: pointer;
	overflow-wrap: break-word;
	overflow: hidden;
	max-height: 50%;
}

.asset .embargo-link {
	position: absolute;
	top: -0.75rem;
	right: 1.75rem;
	background-color: var(--action-color);
	color: var(--action-text-color) !important;
}

.asset.publish-in-future .asset-text {
	padding-bottom: 2rem;
}

.asset .embargo-datetimepicker-wrapper {
	position: absolute;
	bottom: 0;
	max-width: 100%;
	left: 50%;
	z-index: 6;
	transform: translateX(-50%);
}

.asset .embargo-datetimepicker-wrapper.readonly {
	pointer-events: none;
}

/* ================== */
/* ==== DOCUMENT ==== */
/* ================== */
.asset.document .asset-text {
	opacity: 1;
	background-color: unset;
	color: #2F6D81;
	text-align: center;
}

.asset .large-icon {
	position: relative;
	color: #2F6D81;
}
.asset .video-icon:before {
	color: white;
}
.asset .large-icon:before {
	position: absolute;
	z-index: 5;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 5rem;
}
.asset.has-error .large-icon:before {
	z-index: 0;
}

/* ================== */
/* ===== VIDEO ====== */
/* ================== */
.asset.video .asset-text {
	opacity: 1;
}


/* ================== */
/* ===== RIBBON ===== */
/* ================== */
.asset .status-ribbon {
	overflow: hidden;
	width: 60%;
	height: 60%;

	position: absolute;
	top: -2px;
	right: -2px;
	z-index: 5;
}

.asset .status-ribbon > div {
	position: absolute;
	top: 25%;
	right: -65%;
	z-index: 5;

	width: 200%;
	height: 20%;
	transform: rotate(45deg);

	background-color: black;
	color: white;
	box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
	
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;
	font-weight: bold;
}

.asset .status-ribbon.needs-crop > div {
	background-color: var(--attention-color);
	color: var(--attention-fg-color);
}
.asset .status-ribbon.required > div {
	background-color: #F2A63A;
	color: white;
}