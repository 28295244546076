/* Content items */
.c6-contentItem {
	display: flex;
	min-height: 4rem;

	/* user-select: contain; */ /* Funkar endast i typ Edge ännu */
	user-select: none; /* HACK: To enable shift click multi selection in STAR 2 */
}

.c6-contentItem.sel,
.c6-contentItem.sel:hover {
	color: var(--success-color);
	background-color: var(--selected-color);
}

.c6-contentItem .c6-content {
	padding: 10px;
	align-self: center;
}

.c6-contentItem .c6-content.max {
	flex: 1;
}

.c6-contentItem .c6-content.min {
	width: max-content;
}

/* Placeholder items */
.c6-placeholderItem .large-icon:before {
	vertical-align: sub;
}


/* Make it more clear which item the user has opened the IconMenu for */
.c6-contentItem.has-open-icon-menu {
	background-color: var(--content-hl-light-color);
}