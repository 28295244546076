.c6-ratings {
	flex-direction: column;
}

.c6-ratings .c6-editor {
	padding-top: 0;
	overflow-y: unset !important;

	display: flex;
	flex-direction: column;
}

.c6-ratings .step-content {
	padding: 10px 20px;
	flex-grow: 1;
}

.c6-ratings .c6-editor .step-content-loading {
	padding: 10px 20px;
	margin: 0;
	flex: 1;
	text-align: center;
}

.c6-ratings .tab-content h1,
.c6-ratings .wizard h1 {
	margin: 6px 0;
	text-align: center;
	padding: 20px;
}

.c6-ratings h2 {
	margin-bottom: 0;
}



/* Actionbar & Metadata preview */
.c6-ratings .c6-actionbar .right {
	position: relative;
}

.c6-ratings .c6-actionbar .c6-mui-toggle {
	margin-right: 5px;
}

.c6-ratings .c6-actionbar label > span {
	white-space: nowrap;
	text-transform: none;
}

.c6-ratings .c6-metadata-preview {
	border: none;
}

.c6-ratings.hide-references .c6-metadata dl {
	display: none;
}



/* Tabs */
.c6-ratings .tabs {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	background-color: #516976;
	cursor: pointer;
}

.c6-ratings .tabs.readonly {
	opacity: 0.8;
	cursor: not-allowed;
}

.c6-ratings .tab {
	flex: 1;
	transition: all .3s ease-out;
	background-color: transparent;
	color: white;
	user-select: none;
	border-bottom: 3px solid transparent;
}

.c6-ratings .tab.active {
	background-color: var(--action-color);
	color: white;
	border-bottom-color: var(--area-hl-color);
}

.c6-ratings .tab > p {
	padding: 5px;
	text-align: center;
}

.c6-ratings .tab.active > p:before {
	opacity: 1;
}

.c6-ratings .tab > p:before {
	opacity: 0;
}

.c6-ratings .tab-content {
	flex: 1;
	overflow-y: scroll;
}

.c6-ratings .step-content.symbols-age,
.c6-ratings .step-content.name-date,
.c6-ratings .step-content.other-countries {
	min-width: 300px;
	max-width: 520px;
	margin: 0 auto;
}

.c6-ratings .step-content.symbols-age p {
	margin-top: 16px;
	font-size: 12px;
	color: var(--text-lighter-color);
}

.c6-ratings .step-content {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
}

.c6-ratings .step-content > .content-full {
	grid-column: 1 / 3;
}
.c6-ratings .step-content > .content-left {
	grid-column: 1 / 2;
}
.c6-ratings .step-content > .content-right {
	grid-column: 2 / 3;
}




/* Rating criterias wizard */
.c6-ratings .wizard {
	flex: 1;
	overflow-y: auto;
}
.c6-ratings .wizard .language-switch {
	text-align: center;
	margin-bottom: 10px;
}
.c6-ratings .criterias {
	flex: 1;
}

.c6-ratings .descriptions {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}

.c6-ratings .description-group > p {
	margin: 0;
	padding: 5px 10px;
	border: 1px solid var(--line-color);
	background-color: var(--modal-area-color);
	cursor: pointer;
}

.c6-ratings .description-group > div {
	border: 1px solid var(--line-color);
	border-top: none;
}

.c6-ratings .description-group .description {
	background-color: var(--content-light-color);
	margin-left: 0;
	margin-right: 0;
	width: 100%;
}

.c6-ratings .description-group .description:first-child {
	padding-top: 5px
}

.c6-ratings .description-group .description input[disabled] {
	opacity: 0;
}

.c6-ratings .description-group .description-age,
.c6-ratings .symbols-age .age {
	display: inline-block;
	transition: all .2s ease-in-out;
	background-color: #373737;

	padding: 4px;
	border-radius: 50%;
	margin-right: 4px;
	width: 22px;
	height: 22px;

	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
}

.c6-ratings .description-group .description-age {
	/* position: absolute;
	top: 0;
	left: -37px; */
}

.c6-ratings .step-button {
	margin: 20px !important;
}

.c6-ratings .step-content input[type="checkbox"]+div label {
	white-space: normal;
}

.c6-ratings .language-switch {
	margin-top: 10px;
}

.c6-ratings .language-switch a {
	color: var(--action-color);
}

.c6-ratings .c6-tags {
	min-height: 46px;
}

.c6-ratings.error-name .name-date .c6-tagsfield > label {
	color: var(--attention-color);
}

.c6-ratings .c6-tag {
	white-space: nowrap;
}

.c6-ratings .c6-tag > button {
	vertical-align: unset;
}


.c6-ratings .country-age-rating {
	margin-right: 10px;
	margin-top: 30px;
}
.c6-ratings .c6-mui-select {
	min-width: 150px;
}
