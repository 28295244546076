.c6-cms-datetimepicker {
    
}

.c6-cms-datetimepicker .custom-time-section {
    justify-content: flex-end;
    font-size: 17px;
}

.c6-cms-datetimepicker .custom-time-section .numInputWrapper {
    width: auto;
    flex: unset;
    min-width: 50px;
}

.c6-cms-datetimepicker .custom-time-section .numInput {
    font-size: 20px;
}


.c6-cms-datetimepicker .custom-time-section .time-label {
    order: -1;
    flex: 1;
    white-space: nowrap;
	padding: 0 5px;
}


.c6-cms-datetimepicker .confirm-container {
	display: flex;
	height: 40px;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	font-size: 1.2rem;
	background: var(--content-light-color);
	border-radius: 0 0 var(--comet-border-radius) var(--comet-border-radius);
	overflow: hidden;
}

.c6-cms-datetimepicker .confirm-container button {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	
	cursor: pointer;
	font: inherit;
	outline: none;
	border: none;
}

.c6-cms-datetimepicker .confirm-container .confirm {
	color: var(--action-color);
	font-weight: bold;
}

.c6-cms-datetimepicker .confirm-container .cancel {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
}