:root {
    --bg-color: #333333; /* #d1e1e2; /* #c7e0e2;  #CFD8DC; */
    --bg-darker-color: #263238;
    --bg-dark-color: #37474F;
    --bg-inset-color: #c2d9da;
    --login-bg-color: #cde1e2;
    --login-dialog-bg-color: #fff;
    --login-dialog-header-bg-color: #ECEFF1;
    --text-color: #212121;
    --text-semilight-color: #405050;
    --text-light-color: #607D8B;
    --text-lighter-color: #90A4AE;
    
    --area-color: #026f83;
    --area-darker-color: #006779;
    /*--area-light-color: #a3bec1;*/
    --area-semilight-color: #A8C2C4;
    --area-light-color: #b8d2d4; /* #B0BEC5; */
    --area-lighter-color: #deeced;
    --area-lightest-color: #fff;
    --area-hl-color: #CDDC39;
    
    --label-color: #607D8B;
    
    --accent-color: #fff;
    --accent-lighter-color: #fff;
    --accent-bg-color: #74ab36; /* #4CAF50; */
    
    --content-bg-color: #fff;
    --content-fg-color: #fff;
    --content-hl-color: #d9f7fb; /* rgb(244,252,253); */
    --content-hl-light-color: #edfcfe;
    --content-hover-color: rgba(0, 188, 212, .1);
    --content-light-color: #f7f7f7;
    --content-secondary-color: #ecf3f5;
    --content-header-bg-color: #fff;
    --content-header-fg-color: var(--area-color);
    
    --connected-color: #cdf59a;
    --selected-color: #e7ffcc; /* #e5fbd9; */
    --selected-transparent-color: rgba(226, 255, 156, 0.95);
    
    --action-color: #06a7b1; /* C5 blue */
    --action-demihl-color: #23B4BE;
    --action-hl-color: #45d0d6;
    --action-hl-light-color: #5ad0d8; /* #4dd1e2; */
    --action-ok-color: #35ff14;
    --action-dim-color: #06969f;
    --action-text-color: #fff;
    /*
    --action-color: #00BCD4;
    --action-hl-color: #4DD0E1;
    */
    
    --line-color: #CFD8DC;
    
    --bar-color: rgb(50, 65, 73);
    /*--bar-transparent-color: rgba(86,115,129,0.85);*/
    --bar-transparent-color: rgba(0, 87, 103, 0.85);
    
    --modal-bg-color: #fff;
    --modal-area-color: #ECEFF1;
    --modal-area-light-color: #ffffff;
    
    --attention-color: #ef5350;
    --attention-fg-color: #fff;
    --attention-hl-color: #f76663;
    --attention-hl-text-color: #ff7b78;
    
    --error-color: #f44336;
    --error-fg-color: #fff;
    
    --ok-color: #4caf50;
    --success-color: #66bb6a;
    
    --pending-color: #ffa125;
    
    --note-color: #fff58d;
    --note-fg-color: rgb(214, 174, 10);
    --note-darker-color: rgb(255, 230, 128);
    
    --default-font: Roboto, Helvetica, Arial, Verdana, sans-serif;
    
    
    --comet-border-radius: 3px;

    --image-loading-bg-color: #e4e4e4;
    --image-loading-stripe-color: #efefef;

    --side-nav-bg-color: #048897;
    --side-nav-fg-color: #fff;
    --side-nav-hl-color: var(--area-hl-color);
}

:root.env-acceptance {
    --area-color: #ff7900;
    --action-color: #f89f30;
    --action-hl-color: #E08020;
    --modal-area-color: #ffd699;
    --login-bg-color: #fc9d5e;
}

:root.product-horizon {
    --area-color: #e74f0d;
    --action-color: #ff8856;
    --action-hl-color: #ff7338;
    --modal-area-color: #ffefe5;
    --login-bg-color: #ffddcf;
    --bg-color: #ffddcf;
    --bar-transparent-color: rgba(231, 78, 13, 0.78);
    --selected-color: rgb(204, 255, 221);
    --selected-transparent-color: rgba(204, 255, 221, 0.95);
}




:root.c6-theme-dark {
    /* ====== */
    /*  DARK  */
    /* ====== */
    /* --color-primary-100: #06a7b1;
    --color-primary-200: #42b1b9;
    --color-primary-300: #61bbc2;
    --color-primary-400: #7bc4ca;
    --color-primary-500: #92ced3;
    --color-primary-600: #a9d8dc;
    --color-primary-700: #b9e8ec;
    --color-primary-800: #c9f8fc;
    --color-primary-900: #d4f9fd;
    --color-primary-1000: #e5fbfe;

    --color-surface-100: #263238;
    --color-surface-200: #3b464b;
    --color-surface-300: #515a60;
    --color-surface-400: #677074;
    --color-surface-500: #7f868a;
    --color-surface-600: #979da0;

    --color-surface-mixed-100: #2b5e66;
    --color-surface-mixed-200: #436f76;
    --color-surface-mixed-300: #5a7f86;
    --color-surface-mixed-400: #719196;
    --color-surface-mixed-500: #88a2a7;
    --color-surface-mixed-600: #9fb4b8; */

    /* ====== */
    /* DARKER */
    /* ====== */
    --color-primary-100: #05969f;
    --color-primary-200: #3b9fa6;
    --color-primary-300: #4cb2ba;
    --color-primary-400: #64bac1;
    --color-primary-500: #79c3c9;
    --color-primary-600: #8dcbd1;
    --color-primary-700: #98dde3;
    --color-primary-800: #9ef2fa;
    --color-primary-900: #b1f5fb;
    --color-primary-1000: #d0f9fd;

    --color-surface-100: #171e22;
    --color-surface-200: #232a2d;
    --color-surface-300: #31363a;
    --color-surface-400: #3e4346;
    --color-surface-500: #4c5053;
    --color-surface-600: #595f61;

    --color-surface-mixed-100: #1a383d;
    --color-surface-mixed-200: #284347;
    --color-surface-mixed-300: #364c50;
    --color-surface-mixed-400: #43585b;
    --color-surface-mixed-500: #4d6468;
    --color-surface-mixed-600: #577176;



    --bg-color: var(--color-surface-100);
    --login-bg-color: var(--color-surface-100);
    --login-dialog-bg-color: var(--color-surface-200);
    --login-dialog-header-bg-color: var(--color-surface-300);
    --bg-inset-color: var(--color-surface-mixed-200);

    --side-nav-bg-color: var(--color-surface-mixed-100);
    --side-nav-fg-color: var(--color-primary-500);
    --side-nav-hl-color: white;

    --area-color: var(--color-surface-mixed-200);
    --area-semilight-color: var(--color-surface-300);
    --area-lighter-color: var(--color-surface-200);
    --area-lightest-color: var(--color-surface-400);

    --text-color: var(--color-primary-600);
    --text-light-color: var(--color-primary-400);
    
    --modal-bg-color: var(--color-surface-100);
    --modal-area-color: var(--color-surface-200);
    --modal-area-light-color: var(--color-surface-300);

    --bar-color: var(--color-surface-300);

    --action-color: var(--color-primary-100);
    --action-hl-color: var(--color-primary-200);
    --action-text-color: var(--color-primary-1000);

    --content-bg-color: var(--color-surface-mixed-200);
    --content-fg-color: var(--color-primary-600);
    --content-secondary-color: var(--color-surface-300);
    --content-secondary-border-color: var(--color-surface-mixed-100);
    --content-hl-light-color: var(--color-surface-mixed-200);
    --content-header-bg-color: var(--color-surface-mixed-100);
    --content-header-fg-color: var(--color-secondary-600);
    --content-light-color: var(--color-surface-400);
    --content-hl-color: var(--color-surface-mixed-400);

    --accent-color: var(--color-primary-600);
    --accent-bg-color: var(--color-surface-mixed-400);
    --accent-lighter-color: var(--color-primary-700);
    --line-color: var(--color-surface-mixed-300);
    
    --image-loading-bg-color: var(--color-surface-300);
    --image-loading-stripe-color: var(--color-surface-400);
    
    --error-color: #e0675e;
    --attention-color: #ee6865;
    --selected-color: #435b45;
    
    --fc-border-color: var(--line-color);
    --fc-neutral-bg-color: var(--color-surface-200);
    --fc-page-bg-color: var(--color-surface-100);
    --fc-list-event-hover-bg-color: var(--color-surface-100);
}

/* Wait with defining --bg-color until Comet has loaded and we have a theme. */
/* This way we won't disturb dark mode users with a bright flash */
:root.c6-theme-light {
    --bg-color: #d1e1e2;
}
