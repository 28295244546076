.c6-global-uploader {
	position: fixed;
	z-index: 2000;
	bottom: 10px;
	right: 10px;
	width: 400px;
	max-width: calc(100vw - 100px);
	max-height: calc(100vh - 100px);

	background-color: var(--area-lightest-color);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
				0 6px 6px rgba(0, 0, 0, 0.23);
	border-radius: 5px;
	overflow: hidden;

	opacity: 0;
	transition: all .3s ease-in-out;
	pointer-events: none;
}
.c6-global-uploader.show {
	opacity: 1;
	pointer-events: all;
}

.c6-global-uploader .general-info {
	height: 36px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	color: white;
	background-color: var(--area-color);
	transition: background-color .3s ease-in-out;
	opacity: 0.9;
	cursor: pointer;
}

.c6-global-uploader .general-info.failed {
	background-color: var(--error-color);
}

.c6-global-uploader .general-info > .expand {
	margin-left: auto;
	margin-right: 0;
}

.c6-global-uploader .hide-forever {
	color: var(--text-light-color);
	padding: 0;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity .4s ease-in-out;

	display: flex;
	justify-content: space-between;
	align-items: center;
}
.c6-global-uploader.expand .hide-forever {
	padding: 5px;
	max-height: unset;
	height: 36px;
	opacity: 1;
}

.c6-global-uploader .hide-forever button {
	height: unset;
	line-height: unset;
	padding: 3px 5px;
}

.c6-global-uploader .file-details {
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity .4s ease-in-out;
}
.c6-global-uploader.expand .file-details {
	max-height: calc(100vh - 100px - 70px);
	overflow-y: auto;
	opacity: 1;
}

.c6-global-uploader .uploader-files:not(:last-child) {
	margin-bottom: 20px;
}

.c6-global-uploader .uploader-files > p {
	padding: 5px;
	margin: 0;
	color: var(--text-light-color);
	border-bottom: 1px solid var(--area-lighter-color);
}
.c6-global-uploader .uploader-files > p > .c6-link {
	padding: 1px;
}

.c6-global-uploader .uploader-files .file {
	display: flex;
	background-color: var(--content-bg-color);
	max-height: 100px;
	min-height: 80px;
	overflow: hidden;
	border-bottom: 1px solid var(--area-lighter-color);
}
.c6-global-uploader .uploader-files .file:not(:last-child) {
}

.c6-global-uploader .uploader-files .file .thumbnail-container {
	font-size: 0;
	min-width: 100px;
	max-width: 100px;
	height: 80px;
}

.c6-global-uploader .uploader-files .file .thumbnail {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.c6-global-uploader .uploader-files .file .thumbnail,
.c6-global-uploader .uploader-files .file .thumbnail > svg {
	max-width: 100px !important;
	max-height: 80px !important;
}
.c6-global-uploader .uploader-files .file .thumbnail > svg > path:first-child {
	fill: transparent;
}

.c6-global-uploader .uploader-files .file .file-info {
	display: flex;
	flex-direction: column;
	padding: 5px;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
}

.c6-global-uploader .uploader-files .file .name {
	font-weight: bold;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-semilight-color);
}

.c6-global-uploader .uploader-files .file .size {
	color: var(--text-light-color);
}

.c6-global-uploader .uploader-files .file .status {
	margin-left: 5px;
}

.c6-global-uploader .uploader-files .file .progress-bar-container {
	display: block;
	margin-top: auto;
	height: 10px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	position: relative;
}

.c6-global-uploader .uploader-files .file .progress-bar {
	height: 10px;
	background-color: var(--action-color);
}
.c6-global-uploader .uploader-files .file .progress-bar:before {
	position: absolute;
	top: -18px;
	right: 0;
	content: "";
}
.c6-global-uploader .uploader-files .file .progress-bar[aria-valuenow="100"] {
	background-color: var(--success-color);
}
.c6-global-uploader .uploader-files .file .progress-bar[aria-valuenow="100"]:before {
	content: "Complete";
	color: var(--success-color);
}
.c6-global-uploader .uploader-files .file:not(.uploadsuccessful):not(.uploadfailed) .progress-bar[aria-valuenow="100"] {
	animation: processing-bg-color .7s ease-in-out infinite alternate;
}
.c6-global-uploader .uploader-files .file:not(.uploadsuccessful):not(.uploadfailed) .progress-bar[aria-valuenow="100"]:before {
	content: "Processing...";
	color: var(--action-hl-color);
}

/* ERRORS */
.c6-global-uploader .uploader-files .file.uploadfailed {
	background-color: rgba(210, 50, 50, 0.07);
}
.c6-global-uploader .uploader-files .file.uploadfailed .progress-bar {
	background-color: var(--error-color);
}
.c6-global-uploader .uploader-files .file.uploadfailed .progress-bar:before {
	content: "Failed";
	color: var(--error-color);
}
.c6-global-uploader .uploader-files button.retry {
	margin: 2px 0;
    height: 22px;
    width: 56px;
    line-height: 22px;
    padding: 0 5px;
}
.c6-global-uploader .uploader-files button.retry > span {
	font-size: .9rem;
	display: flex;
	align-items: center;
}


/* BUTTON: REMOVE */
.c6-global-uploader .uploader-files button.remove {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	height: 2rem;
	min-width: unset;
	width: 2rem;
	line-height: 2rem;
	color: var(--attention-color);

	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-in-out;
}

.c6-global-uploader .uploader-files .file.uploadsuccessful:hover button.remove,
.c6-global-uploader .uploader-files .file.uploadfailed:hover button.remove {
	opacity: 1;
	pointer-events: all;
}

@keyframes processing-bg-color {
	to {
		background-color: var(--pending-color);
		background-color: var(--action-hl-color);
	}
}

@keyframes processing-color {
	to {
		color: var(--pending-color);
		color: var(--action-hl-color);
	}
}