/* Preview */
.c6-preview {
	/*flex: 0 0 100px;*/
	width: 100px;
	min-width: 100px; /* For Safari */
	height: 56px;

	/* To vertically align sport logos (possibly breaks Chrome on Windows scrolling behavior) */
	/* display: flex;
	align-items: center;
	justify-content: center; */

	position: relative;
}

.c6-preview img {
	/*object-fit: contain;*/ /* Makes images stay inside the img width/height box. (Possibly breaks Chrome on Windows scrolling behavior) */
	display: block;
}

.c6-preview.is-visible {
	background-color: var(--image-loading-bg-color);
	background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, var(--image-loading-stripe-color) 5px, var(--image-loading-stripe-color) 10px);
}

.c6-preview.is-loading {
	background-color: #efefef;
}

.c6-preview.icon-error:before {
	padding: 0;
	color: var(--attention-color);
	line-height: 56px;
	width: 100%;
	text-align: center;
}

/* .c6-preview.upcoming img { // Moved to a more general class
	opacity: 0.3;
} */

.c6-preview.cover {
	/*flex: 0 0 40px;*/
	width: 40px;
	min-width: 40px; /* For Safari */
}

.c6-preview.thumbnail {
	/*flex: 0 0 200px;*/
	width: 200px;
	min-width: 200px; /* For Safari */

	height: 112px;
	/*min-height: 112px;*/
}

.c6-preview-video {
	position: relative;
	cursor: pointer;
	pointer-events: none;
}

.c6-preview-video:before {
	display: block;
	position: absolute;
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 40px;
	z-index: 1;
}

.c6-app:not(.c6-editor) .c6-preview-video:before,
.c6-preview-video.status-Ready:before {
	/* content: "▶"; */
	content: "\25B6"; /* 25B6 = play arrow right */
	text-shadow: 0 0 2px #000;
	font-weight: bold;
}

.c6-preview-video > * {
	pointer-events: auto;
}

/* Video status icons */
.c6-preview-video[class^="icon-"]:before,
.c6-preview-video[class*=" icon-"]:before {
	/*position: absolute;*/
	/*top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
	/*font-size: 40px;*/
	color: var(--accent-color);
}

.c6-preview-video[data-status]:not(.status-Ready):after {
	content: attr(data-status);
	display: inline-block;
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 5px;
	font-size: .85rem;
	color: var(--accent-color);
}

.c6-preview-video[class^="icon-"] .c6-preview.is-visible,
.c6-preview-video[class*=" icon-"] .c6-preview.is-visible {
	background-image: none;
	background-color: var(--accent-bg-color);
}

.c6-preview-video[class^="icon-"].icon-report .c6-preview.is-visible,
.c6-preview-video[class*=" icon-"].icon-report .c6-preview.is-visible {
	background-color: var(--attention-color);
}