.c6-editor-approval .MuiTypography-root {
    color: var(--modal-area-color);
    font-size: 12px;
}

.c6-theme-dark .c6-editor-approval .MuiTypography-root {
    color: var(--text-color);
}

.c6-editor-approval .MuiSwitch-root {
    height: 36px;
    padding: 11px 8px;
    width: 50px;
}

.c6-editor-approval .MuiSwitch-switchBase {
    padding: 8px 5px;
}

.c6-editor-approval .MuiSwitch-input:disabled {
    opacity: 0;
}