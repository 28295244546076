/* ================== */
/* ===== GENERAL ==== */
/* ================== */
.c6-osd-todo {
	grid-template-columns: 60% 1fr;
	grid-column-gap: 15px;

	background-color: var(--bg-color);
	font-size: 14px;

	height: calc(100vh - 128px) !important;
}

.c6-osd-todo.container-missing {
	justify-content: center;
}

.c6-osd-todo h2 {
	font-size: 1.2em;
	margin-bottom: 4px;
}

.c6-osd-todo .c6-main {
	overflow-x: hidden;
}


/* ================== */
/* ===== FILTER ===== */
/* ================== */
.c6-osd-todo > .c6-filter {
	grid-column: 1 / span 2;
	padding: 10px 15px;
	margin-top: 0;

	background-color: var(--bar-color);
	color: white;
}

.c6-osd-todo > .c6-filter h1 {
	line-height: 2.5rem;
}


/* ================== */
/* ==== SECTIONS ==== */
/* ================== */
.c6-osd-todo > .todo-files {
	grid-column: 1 / span 1;
	margin-left: 15px;
}

.c6-osd-todo > .todo-activity {
	grid-column: 2 / span 1;
	margin-right: 10px;
}



/* =================== */
/* == MEDIA QUERIES == */
/* =================== */
@media screen and (max-width: 1900px) {
	.c6-osd-todo {
		font-size: 12px;
	}
}

@media screen and (max-width: 1510px) {
	.c6-osd-todo button.c6[data-shorttitle]:after {
		content: attr(data-shorttitle);
		display: inline;
		vertical-align: top;
		margin-left: .25rem;
	}
	.c6-osd-todo button.c6[data-shorttitle] span {
		display: none;
	}
}

@media screen and (max-width: 1285px) {
	.c6-osd-todo {
		font-size: 11px;
	}
}