.c6-filter {
	margin: 20px 0;
	/*-webkit-backdrop-filter: blur(10px);*/
}
.c6-filter.secondary {
	padding: 5px;
	margin-bottom: 0;
	background-color: var(--area-lighter-color);
}

.c6-filter > div {
	/*display: flex;
	align-self: center;*/
	line-height: 2.5rem;
}
.c6-filter.secondary > div {
	line-height: unset;
}

.c6-filter > div > p,
.c6-filter > div > div {
	line-height: 1.5rem;
}

.c6-filter .group {
	margin-right: 20px;
}

.c6-filter .right {
	justify-content: flex-end;
	padding-right: 5px;
}

/* Title */
.c6-filter h1 {
	display: inline-block;
	margin: 0 .5rem;
	vertical-align: top;
}

.c6-filter h1:first-child {
	margin-left: 0;
}

/* Title links */
.c6-filter h1 a {
	color: var(--text-color);
	text-decoration: none;
	border-bottom: 1px dotted var(--action-color);
	padding: 3px;
}

.c6-filter h1 a:hover {
	color: var(--accent-color);
	background-color: var(--action-hl-color);
	text-decoration: none;
	border-bottom-color: transparent;
}

/* Info */
.c6-filter .info,
.c6-filter .info-large {
	display: inline-block;
	border-radius: .5rem;
	margin: 0 0 0 .5rem;
	font-size: .75rem;
	line-height: 1.5rem;
	vertical-align: text-bottom;
	padding: 0.15rem 0.5rem;
	background-color: var(--text-light-color);
	color: #fff;
	text-transform: uppercase;
}

.c6-filter .info-large {
	font-size: 1rem;
	line-height: 1.75rem;
}

.c6-filter .info.alert {
	background-color: #f44336;
}

/* Secondary filter row */
.c6-filter + .c6-filter {
	margin: -10px 0 10px 0;
}

/* Filters inside modals */
.c6-modal-body .c6-filter {
	background-color: #CFD8DC;
	padding: 15px 20px 20px 20px;
	margin: 2.5rem 0 0 0;
}