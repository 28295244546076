.c6-wysiwyg {
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
}

.c6-wysiwyg-toolbar {
    background-color: var(--area-lighter-color);

    display: flex;
    align-items: stretch;

    border: 1px solid var(--area-lighter-color);
    border-bottom: none;
    border-top: none;
}

.c6-wysiwyg-toolbar .divider {
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.c6-wysiwyg-toolbar button {
    border: 1px solid transparent;
    background: none;
    margin-right: 2px;
    padding: 1px 4px;
    cursor: pointer;

    font-family: "Times New Roman", Times, serif;
    font-size: 18px;
}

.c6-wysiwyg-toolbar button.is-active {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    margin-bottom: -1px;
}

.c6-wysiwyg-toolbar button > span {
    display: flex;
    margin-top: -1px;
}

.c6-wysiwyg-content {
    padding: 0 10px;
    border: 1px solid var(--area-lighter-color);
    color: initial;
}
.c6-wysiwyg-toolbar + .c6-wysiwyg-content {
    border-top-color: transparent;
}

/* These styles should probably be applied in the Press service too */
.c6-wysiwyg-content h1,
.c6-wysiwyg-content h2,
.c6-wysiwyg-content h3,
.c6-wysiwyg-content h4,
.c6-wysiwyg-content h5,
.c6-wysiwyg-content h6,
.c6-wysiwyg-content p {
    font-size: revert;
    font-weight: revert;
    word-break: break-word;
}




.c6-editor .c6-wysiwyg-widget {
    padding-top: 15px;
}
.c6-editor .c6-wysiwyg {
    margin-bottom: 20px;
}