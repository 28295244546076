:local .empty {
	position: relative;
	top: -50px;
	line-height: 1.5rem;
}

:local .empty-v2 {
	height: 100%;
	display: flex;
	justify-content: center;
  	align-items: center;
}