.c6-dashboard {
	padding: 20px;
}

.c6-dashboard > .apps {
	margin: -10px;
	display: flex;
	flex-wrap: wrap;
}

.c6-dashboard section {
	color: var(--content-fg-color);
	background-color: var(--area-color);
	margin: 10px;
	padding: 20px;
	/*width: calc(25% - 20px);*/
	flex: 1;
	min-width: 250px;
	border-radius: var(--comet-border-radius);
}

.c6-theme-dark .c6-dashboard section {
	background-color: var(--content-bg-color);
}

.c6-dashboard header {
	display: flex;
	padding: 20px;
	font-size: 1.5rem;
	margin: -20px -20px 20px -20px;
	color: var(--content-header-fg-color);
	background-color: var(--content-header-bg-color);
	border-radius: var(--comet-border-radius) var(--comet-border-radius) 0 0;
}

.c6-dashboard header:before {
	margin-right: 20px;
}

.c6-dashboard a {
	color: var(--content-fg-color);
	text-decoration: none;
}

.c6-dashboard a:hover {
	color: var(--side-nav-hl-color);
}

.c6-dashboard .folder > article {
	text-decoration: underline;
	font-weight: bold;
	margin-bottom: 5px;
}

/* App */
.c6-dashboard article {
	margin-bottom: 10px;
	font-size: 1rem;
}
.c6-dashboard:not(.new-navigation) article {
	margin-bottom: 20px;
}

.c6-dashboard a:last-child article {
	margin-bottom: 0;
}

.c6-dashboard article::before {
	font-size: 1.5rem;
	vertical-align: sub;
	margin-right: 20px;
}




.c6-dashboard.new-navigation {
	max-width: 1200px;
	margin: 20px auto 0;
}

.c6-dashboard.new-navigation .apps {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(3, auto);
	grid-template-areas:
		"vod vod live live linear linear"
		"metadata metadata metadata star star star"
		"storefront storefront storefront shield shield shield";
}

.c6-dashboard.new-navigation .apps .vod { grid-area: vod }
.c6-dashboard.new-navigation .apps .live { grid-area: live }
.c6-dashboard.new-navigation .apps .linear { grid-area: linear }
.c6-dashboard.new-navigation .apps .metadata { grid-area: metadata }
.c6-dashboard.new-navigation .apps .star { grid-area: star }
.c6-dashboard.new-navigation .apps .storefront { grid-area: storefront }
.c6-dashboard.new-navigation .apps .shield { grid-area: shield }

.c6-dashboard.new-navigation .apps section > div {
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
}

.c6-dashboard.new-navigation section {
	min-width: 200px;
}


@media screen and (max-width: 750px) {
	.c6-dashboard.new-navigation .apps {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(4, auto);
		grid-template-areas:
			"vod live"
			"linear linear"
			"metadata metadata"
			"star storefront"
			"shield shield";
	}
}

@media screen and (max-width: 530px) {
	.c6-dashboard.new-navigation .apps {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(7, auto);
		grid-template-areas:
			"vod"
			"live"
			"linear"
			"metadata"
			"star"
			"storefront"
			"shield";
	}
}