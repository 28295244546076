.iframe-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.iframe-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
}