.c6-comments {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;

	user-select: text;
}
.c6-comments > div:first-of-type {
	padding: 0;
	max-height: calc(100% - 112px);
	background-color: transparent;
}
.c6-comments > .new-comment {
	margin-top: 10px;
	padding: 10px;
}

.c6-comments .wrapper {
	width: 100%;
	border-bottom: 1px solid var(--bg-color);
}

.c6-comments .comment {
	word-break: break-all;
	padding: 6px 10px;
	border-top: 1px solid var(--bg-color);
	background-color: var(--content-bg-color);

	font-size: 1rem;
}

.c6-comments .comment.error,
.c6-comments .comment.error.by-current-user {
	background-color: var(--attention-color);
	color: var(--attention-fg-color);
}
.c6-comments .comment.warning,
.c6-comments .comment.warning.by-current-user {
	background-color: var(--pending-color);
	color: var(--attention-fg-color);
}
.c6-comments .comment.error > .comment-message > span,
.c6-comments .comment.warning > .comment-message > span {
	color: inherit;
}

.c6-comments .comment.by-current-user {
	background-color: var(--content-light-color);
}

.c6-comments .comment > .comment-message {
	min-height: 16px;
	color: inherit;

	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;

	position: relative;
}

.c6-comments .comment > .comment-message > span {
	flex-basis: 75px;
	white-space: nowrap;
	text-align: right;
	margin-right: 10px;
	color: var(--text-light-color);
}
.c6-comments .comment > .comment-message .text {
	flex: 1;
}

.c6-comments .comment > .comment-info {
	min-height: 16px;
	margin: 0;
	padding-left: 85px;
	padding-bottom: 5px;
}
.c6-comments .comment > .comment-info > strong {
	margin-right: 10px;
}
.c6-comments .comment > .comment-info > span {
	font-size: 0.9rem;
	color: var(--text-light-color);
}

.c6-comments .comment a {
	display: none;
	margin-left: 5px;
	padding: 0 1px;
}
.c6-comments .comment.by-current-user:hover a,
.c6-comments .comment.warning:hover a,
.c6-comments .comment.error:hover a {
	display: inline;
}




.c6-comments .comment.same-user-as-next {
	padding-bottom: 2px;
}
.c6-comments .comment.same-user-as-previous {
	border-top: none;
	padding: 0 10px 2px;
}
.c6-comments .comment.same-user-as-previous:last-child {
	padding-bottom: 4px;
}