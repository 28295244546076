.c6-search {
	position: relative;
	margin-left: 1px;
}

.c6-search input {	
	font-family: inherit;
	font-size: inherit;
	width: 10em;
	-webkit-appearance: none;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid transparent;
	padding: 2px 0 1px 1.35rem;
	color: var(--text-color);
}

/* .c6-search input:placeholder-shown {
	user-select: none;
} */

.c6-search input:not([value=""]):focus,
.c6-search input:not([value=""]) {
	background-color: var(--action-color);
	color: #fff;
	border-radius: var(--comet-border-radius);
}

.c6-search input::-webkit-input-placeholder {
	color: var(--text-light-color);
}

.c6-search:hover input::-webkit-input-placeholder {
	color: var(--action-color);
}

.c6-search input::-webkit-search-cancel-button {
	color: #fff;
	margin-right: 2px;
}

.c6-search input[value=""]:hover,
.c6-search input[value=""]:focus {
	border-bottom: 1px solid var(--action-color);
}

.c6-search .icon-search {
	font-size: 1.15rem;
	line-height: 1;
	position: absolute;
	left: 2px;
	top: -1px;
}

.c6-search input:not([value=""]):focus + .icon-search,
.c6-search input:not(:placeholder-shown) + .icon-search {
	color: #fff;
}

.c6-search:hover .icon-search,
.c6-search input[value=""]:hover + .icon-search
.c6-search input[value=""]:focus + .icon-search {
	color: var(--action-color);
}

/* BIG */
.c6-search.big {
	text-align: center;
}

.c6-search.big input {
	padding: 20px 20px 20px 60px;
	font-size: 20px;
	width: 80%;
	max-width: 800px;
	border-bottom: 1px solid var(--text-color);
}

.c6-search.big .icon-search {
	font-size: 1.5rem;
	left: 20px;
	top: -4px;
}