:root {
    --side-nav-width: 50px;
    --side-nav-width-expanded: 250px;
}

.c6-side-navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 25;
    width: var(--side-nav-width);
    height: 100%;
    background: var(--side-nav-bg-color);
    color: var(--side-nav-fg-color);
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.c6-side-navigation,
.c6-side-navigation * {
    transition: all .1s ease,
                width .2s ease;
}

.c6-side-navigation.expand {
    width: var(--side-nav-width-expanded);
    box-shadow: 0 0 8px 10px rgba(0, 0, 0, 0.19),
                0 0 3px 3px rgba(0, 0, 0, 0.23);
}
.c6-side-navigation.pinned {
    box-shadow: none;
}

.c6-side-navigation ~ * {
    margin-left: var(--side-nav-width);
    transition: margin-left .1s ease;
}
.c6-side-navigation.pinned ~ * {
    margin-left: var(--side-nav-width-expanded);
}

.c6-side-navigation [class^="icon-"] > * {
    font-size: 1rem;
    margin-left: 20px;
}
.c6-side-navigation [class^="icon-"]:before {
    font-size: 2rem;
}
.c6-side-navigation:not(.expand) [class^="icon-"] > * {
    opacity: 0;
    width: 0;
    margin-left: 0;
}
.c6-side-navigation [class^="icon-"] > * {
    width: auto;
    opacity: 1;
    white-space: nowrap;
}

.c6-side-navigation.expand .section {
    width: 100%;
}



/* COMET SECTION */
/* ============= */
.c6-side-navigation .comet {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 20px 10px;
    cursor: pointer;
}
.c6-side-navigation.expand .comet {
    padding: 20px 20px;
}

.c6-side-navigation .comet .c6-comet-logo {
    background-color: white;
    border-radius: 5px;
    width: 30px;
    margin-right: 10px;
    aspect-ratio: 1;
    flex-shrink: 0;
}


.c6-side-navigation .comet .info {
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 2rem;
}
.c6-side-navigation.expand .comet .info {
    opacity: 1;
}
.c6-side-navigation .comet .info .title {
    font-size: 1.2rem;
    overflow: hidden;
    max-height: 3rem;
}
.c6-side-navigation .comet .info .version {
    font-size: 0.9rem;
    opacity: 0.75;
    max-height: 1rem;
    overflow: hidden;
}

.c6-side-navigation .comet .buttons {
    position: absolute;
    top: 23px;
    right: 15px;
    display: flex;
}

.c6-side-navigation .comet button {
    background-color: var(--action-color);
    color: var(--action-text-color);
    border: none;
    border-radius: var(--comet-border-radius);
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    opacity: 0;
    pointer-events: none;
}
.c6-side-navigation .comet .pin-expand-button.pinned:before {
    transform: rotate(-90deg);
    margin-left: -3px;
}

.c6-side-navigation .comet button:before {
    font-size: 1.5rem;
}

.c6-side-navigation.expand .comet button {
    opacity: 1;
    pointer-events: all;
}

.c6-side-navigation:not(.expand) .comet.new-version-available {
    animation-name: huerotateVersion;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: infinite;
}

@keyframes huerotateVersion {
    0% {
        filter: hue-rotate(0deg) brightness(1);
        transform: scale(1);
    }
    50% {
        filter: hue-rotate(180deg) brightness(1.5);
        transform: scale(1.2);
    }
    100% {
        filter: hue-rotate(359deg) brightness(1);
        transform: scale(1);
    }
}

/* NEW VERSION SECTION */
/* =================== */
.c6-side-navigation:not(.expand) .new-version {
    opacity: 0;
}
.c6-side-navigation .new-version {
    padding: 0 20px;
}
.c6-side-navigation .new-version .newVersionAvailable {
    background-color: unset;
    white-space: pre;
}

/* MODULES SECTION */
/* =============== */
.c6-side-navigation .modules {
    flex: 1;
    user-select: none;
    padding: 20px 0;
}
.c6-side-navigation.expand .modules {
    padding: 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.c6-side-navigation .modules a {
    text-decoration: none;
    color: inherit;
    pointer-events: none;
    cursor: pointer;
}
.c6-side-navigation.expand .modules a {
    pointer-events: all;
}
.c6-side-navigation .modules .node.root {
    padding: 0 10px;
    margin-bottom: 0.5rem;
}
.c6-side-navigation.expand .modules .node.root {
    padding: 0 20px;
}
.c6-side-navigation .modules .node.root > .node-header {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    pointer-events: all;
}
.c6-side-navigation .modules .node.root > .node-header:before {
    margin-right: 1rem;
    font-size: 2rem;
}
.c6-side-navigation.expand .modules .node.root.active-child.expand > .node-header:not(:hover) {
    color: unset;
    background-color: unset;
    border-color: transparent;
}
.c6-side-navigation .modules .node-header {
    padding: 1px 0;
    margin: 3px 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-left: 2px solid transparent;
    margin-left: -1px; /* Compensate for border */
}
.c6-side-navigation.expand .modules .node:not(.root) .node-header {
    padding-left: 5px;
}
.c6-side-navigation .modules .node:not(.folder) > .node-header:hover,
.c6-side-navigation.expand .modules .node.active > .node-header,
.c6-side-navigation .modules .node.home.active > .node-header,
.c6-side-navigation .modules .node.root.active-child > .node-header {
    color: var(--side-nav-hl-color);
}
.c6-side-navigation.expand .modules .node.active > .node-header {
    border-color: var(--side-nav-hl-color);
}
.c6-side-navigation.expand .modules .node.active > .node-header {
}
.c6-side-navigation .modules .node-header:before {
    margin-right: .5rem;
    font-size: 1.3rem;
}
.c6-side-navigation .modules .node-header span {
    opacity: 0;
}
.c6-side-navigation.expand .modules .node-header span {
    opacity: 1;
}
.c6-side-navigation .modules .node-header .expand {
    margin-left: auto;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
}

.c6-side-navigation .modules .node-children {
    max-height: 0;
    overflow: hidden;
}
.c6-side-navigation.expand .modules .node-children {
    max-height: 500px;
}

.c6-side-navigation .node.folder {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.c6-side-navigation .node.folder:first-of-type {
    margin-top: 0;
}
.c6-side-navigation .node.folder > .node-header {
    cursor: default;
    margin-bottom: 0;
    font-weight: bold;
    text-decoration: underline;
}

.icon-storefront_custom_4:before {
    /* Hack because I just can't get the icon sized properly in Illustrator. Argh... */
    transform: scale(1.1);
}


/* ACCOUNT SECTION */
/* =============== */
.c6-side-navigation .account {
    user-select: none;
    padding: 20px 10px;
}
.c6-side-navigation.expand .account {
    padding: 20px 20px;
}

.c6-side-navigation .account > div {
    margin: 15px 0 0;
    cursor: pointer;
}
.c6-side-navigation .account > div:hover {
    color: var(--area-hl-color);
}

.c6-side-navigation .account > div > div {
    display: flex;
    align-items: center;
}
