/* =============== */
/*      FIELD      */
/* =============== */

.c6-crewfield .add-crew {
	display: flex;
	flex-wrap: wrap;

	position: sticky;
    bottom: 0;
	z-index: 15;
	text-transform: uppercase;
}

.c6-crewfield .add-crew > div {
	font-size: .9rem;
	/* color: rgba(0, 0, 0, 0.3); */
	color: var(--text-lighter-color);
	display: flex;
	align-items: center;
}
.c6-crewfield .add-crew > button {
	margin: 0;
	font-size: .9rem;
}

/* =============== */
/*    CATEGORY     */
/* =============== */
.c6-crew-category {
	margin-top: 16px !important;
}

.c6-crew-category label {
	font-size: 12px;
	/* color: rgba(0, 0, 0, 0.3); */
	color: var(--text-lighter-color);
	/*transition: color .15s ease-in-out;*/
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.c6-crew-category.is-focused label {
	color: rgb(0, 188, 212);
}

/* =============== */
/*    CONTAINER    */
/* =============== */
.c6-crew {
	/*width: calc(100% - 40px);*/
	padding: 5px 2px;
	margin: 0;

	font-family: var(--default-font);

	--person-bg-color: #f0f0f0;
}

.c6-crew.read-only,
.c6-crew .read-only {
	cursor: default;
}

/* =============== */
/*      PERSON     */
/* =============== */
.c6-person {
	display: flex;
	align-items: center;

	padding: 0;
	margin: 10px 0;

	background-color: var(--person-bg-color);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12),
				0 1px 2px rgba(0, 0, 0, 0.18);
}
.c6-theme-dark .c6-person {
	background-color: var(--modal-area-light-color);
}

.c6-person.new {
	justify-content: center;
}

/* HANDLE */
.c6-person > .handle {
	flex-basis: 50px;
	display: flex;
	padding: 10px 0;
}
.c6-person:not(.read-only) > .handle {
	cursor: move;
}
.c6-person > .handle > .icon-person {
	margin: 0 15px 0 5px;
	font-size: 20px;
}
.c6-person > .handle.has-image {
	padding: 0 0 0 10px;
}
.c6-person .c6-handle + * {
	margin-left: 0; /* Override c6-handle negative margin */
}
.c6-person.read-only > .handle > .icon-person {
	margin-left: 25px;
}

/* DATA */
.c6-person > .data {
	flex-grow: 1;
	display: flex;
	align-items: center;
	padding: 10px 0;
}
.c6-person > .data > .person,
.c6-person > .data > .role {
	position: relative;

	flex: 1;
}
.c6-person > .data > .person-role-separator {
	padding: 0 10px;
}
.c6-person > .data > .person > .actor-name,
.c6-person > .data > .role > .role-name {
	font-size: 14px;
}
.c6-person > .data input[type="text"] {
	width: 100%;

	background: none;
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: var(--text-color);

	font-family: var(--default-font);
	font-size: 14px;
}
.c6-person > .data input[type="text"] ~ .material-underline:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -1px;
	left: 50%;
	background-color: transparent;
	height: 2px;
	max-width: 100%;
	width: 0;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.c6-person > .data input[type="text"]:active ~ .material-underline:after,
.c6-person > .data input[type="text"]:focus ~ .material-underline:after {
	background-color: rgb(0, 188, 212);
	max-width: 100%;
	width: 100%;
	left: 0;
}

.c6-person .data .c6-spinner {
	height: 1.2rem;
	width: 1.2rem;
	left: unset;
	right: 5px;
	top: 0;
	background-color: var(--person-bg-color);
}

.c6-person .data .c6-spinner > span {
	top: 0;
	left: 0;
	background: none;
	height: 1.2rem;
	width: 1.2rem;
}

.c6-person .data .c6-spinner > span:before {
	background: var(--text-color);
}

.c6-person .data .c6-spinner > span:after {
	background: var(--person-bg-color);
}

/* REMOVE */
.c6-person > .remove {
	flex-basis: 50px;
}
.c6-person > .remove button {
	height: 100%;
	width: 100%;
	line-height: unset;
	color: transparent;
	transition: color .15s ease-in;
}

.c6-person:not(.read-only):hover > .remove button {
	color: var(--attention-color);
}