/* Editor Base */
.c6-editor {
	min-width: 600px;
	padding-bottom: 0;
}

.scroll > .c6-editor::-webkit-scrollbar-track {
	margin-bottom: 0;
}

.c6-editor form {
	padding-bottom: 20px;
}

.c6-editor .space-above {
	margin-top: 30px;
}

.c6-editor .pad-above {
	padding-top: 20px;
}

.c6-editor .tighten-below {
	margin-bottom: -50px;
}

.c6-editor .separator {
	border-top: 1px solid var(--line-color);
}

.c6-editor .separator-bottom {
	padding-bottom: 18px;
	border-bottom: 1px solid var(--line-color);
}

.c6-editor .padding-bottom {
	padding-bottom: 18px;
}

/* Editor columns */
.c6-editor .cols-2 {
	width: 50%;
	float: left;
}

.c6-editor .cols-2 + :not(.cols-2) {
	clear: both;
}

.c6-editor .array-cols-2 .array-item {
	width: calc(50% - 10px);
	float: left;
	margin-right: 20px;
}

.c6-editor .array-cols-4 .array-item {
	width: calc(25% - 15px);
	float: left;
	margin-right: 20px;
}

.c6-editor .array-cols-2 .array-item:nth-child(even),
.c6-editor .array-cols-4 .array-item:nth-child(4n) {
	margin-right: 0;
}

/* Editor errors */
.c6-editor .panel-danger {
	display: none;
}

.c6-editor .panel-danger ~ .c6-actionbar {
	background-color: #f44336;
}

.c6-editor .panel-danger ~ .c6-actionbar button.c6 {
	color: #fff !important;
}

.c6-editor .panel-danger ~ .c6-actionbar button.icon-check {
	cursor: default;
	color: rgba(255, 255, 255, 0.5) !important;
}

/* Remove when we can send errors through to the widget as in: https://github.com/mozilla-services/react-jsonschema-form/pull/85 */
.c6-editor .error-detail {
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	color: #f44336;
}

.c6-editor .error-detail::first-letter {
	text-transform: uppercase;
}

.c6-editor .errors-placeholder {
	height: 14px;
}
.c6-editor input[type="hidden"] + .errors-placeholder {
	display: none;
}

.c6-editor .form-group.has-error p:empty {
	display: none;
}

.c6-editor .text-danger {
	display: block;
}

.c6-editor fieldset {
	border: 0;
	padding: 0;
	margin: 0;
	/*margin-top: 2.5rem;*/
}

.c6-editor .form-group > div:not(.fieldset):not(.grid-object),
.c6-editor .form-group > label  {
	margin: 0 20px;
}

/*.c6-editor .form-group > label {
	margin-top: 14px;
	margin-bottom: 4px;
	display: none;
}*/

.c6-editor .array-item-list:empty {
	display: none;
}

.c6-editor .array-item-list > div {
	position: relative;
}

.c6-editor fieldset fieldset {
	padding: 0.01em 0 0 0;
	border: 0;
	padding: 0;
	/*margin: 20px 0 20px 0;*/
	margin: 0 0 20px 0;
	min-width: 0;
}

.c6-editor fieldset.field > * {
	padding: 20px;
}

.c6-editor fieldset.field > .row {
	padding-top: 0;
	padding-bottom: 0;
}

/*.c6-editor fieldset.field > .row:last-child {

}*/

.c6-editor fieldset.field > legend {
	background: none;
	/*margin-top: 15px;*/
	display: table;
	width: 100%;
	/*border-top: 1px solid #EEEEEE;*/
	padding-bottom: 2px;
	font-weight: bold;
}

.c6-editor form > div > fieldset > .field-object > fieldset > legend,
.c6-editor form > div > fieldset > .field-array > fieldset > legend {
	background: none;
	/*margin-top: 15px;*/
	display: table;
	width: 100%;
	/*border-top: 1px solid #EEEEEE;*/
	padding: 20px 20px 2px 20px;
	font-weight: bold;
}

.c6-editor form > div > fieldset > .field-object > fieldset > p {
	margin-left: 20px;
}

.c6-editor fieldset.field legend:empty {
	display: none;
}

.c6-editor fieldset .field-description {
	padding-top: 0;
	margin-top: 0;
	padding-bottom: 10px;
}

.c6-editor fieldset fieldset fieldset {
	/*padding: 0.01em 20px 20px 20px;*/
	/*background-color: #f0f0f0;*/
	/*border: 1px solid #CFD8DC;*/
	margin: 0;
	padding: 20px 0;
	/*margin-bottom: 20px;*/
}

.c6-editor fieldset fieldset.field-array .array-item > div > .field > fieldset {
	background-color: #fcfcfc;
	border: 1px solid #CFD8DC;
	margin-bottom: 20px;
}

.c6-editor fieldset fieldset fieldset legend {
	border-top: 0;
	margin: 0 0 -18px 20px; /* NEW ML HACK */
}

.c6-editor fieldset fieldset fieldset fieldset {
	border: 0;
}

/*.c6-editor fieldset fieldset fieldset .form-group {
	margin: 0 20px;
}*/

.c6-editor .field-description {
	font-size: 12px;
	color: rgb(128, 128, 128);
}

.c6-editor legend + .hide {
	display: block;
	margin-top: -40px;
}

/* Super hard coded JSON Schema form overrides #shame */
.c6-editor label[for="root_priority"],
.c6-editor label[for="root_autoGenerated"],
.c6-editor label[for="root_autoGeneratePositionText"],
.c6-editor label[for$="weekdays"],
.c6-editor label[for="root_licenseCategory_id"] {
	display: block;
}

.c6-editor .field-boolean .field-description {
	display: none;
}

/* RJSF Array item add/remove buttons */
.c6-editor .array-item-add {
	margin: 0;
}

.c6-editor .array-item-add .btn-add {
	cursor: pointer;
	padding: 0;
	color: var(--action-color);
	font-family: var(--default-font);
}

.c6-editor .array-item-add .btn-add:hover {
	color: var(--action-hl-color);
}

.c6-editor .array-item-add .btn-add:after {
	content: "Add";
	font-size: 16px;
	margin-left: 2px;
	vertical-align: text-bottom;
}

.c6-editor .array-item-add .btn-add {
	background: transparent;
	border: none;
}

.c6-editor .array-item-remove {
	position: absolute;
	top: -10px;
	right: -12px;
	background: transparent;
	border: none;
}

.c6-editor .glyphicon {
	cursor: pointer;
	color: var(--action-color);
}

.c6-editor .glyphicon:hover:before {
	color: var(--action-hl-color);
}

.c6-editor .glyphicon:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 1.2rem;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c6-editor .glyphicon-remove {
	background-color: #fff;
	border-radius: 7px;
}

.c6-editor .glyphicon-remove:before {
	content: "\e5c9";
}

.c6-editor .glyphicon-plus:before {
	content: "\e147";
}

.c6-editor .glyphicon-arrow-up:before {
	content: "\e5ce";
}
.c6-editor .glyphicon-arrow-down:before {
	content: "\e5cf";
}

/* Debug editor */
.c6-editor pre {
	margin: 20px;
}