@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.woff?29wlzd') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-storefront_custom_4:before {
  content: "\e92c";
}
.icon-television-classic:before {
  content: "\e943";
}
.icon-remote-tv:before {
  content: "\e940";
}
.icon-storefront:before {
  content: "\e941";
}
.icon-minus:before {
  content: "\e93d";
}
.icon-plus:before {
  content: "\e93e";
}
.icon-circle-small:before {
  content: "\e93c";
}
.icon-pin-off-outline:before {
  content: "\e938";
}
.icon-pin-off:before {
  content: "\e939";
}
.icon-pin-outline:before {
  content: "\e93a";
}
.icon-pin:before {
  content: "\e93b";
}
.icon-all-inclusive:before {
  content: "\e933";
}
.icon-server_check:before {
  content: "\e942";
}
.icon-step-forward:before {
  content: "\e92d";
}
.icon-shoe-print:before {
  content: "\e928";
}
.icon-gesture-double-tap:before {
  content: "\e927";
}
.icon-scoreboard-outline:before {
  content: "\e921";
}
.icon-scoreboard:before {
  content: "\e922";
}
.icon-chart-gantt:before {
  content: "\e91b";
}
.icon-newspaper:before {
  content: "\e914";
}
.icon-transfer:before {
  content: "\e90f";
}
.icon-cube-send:before {
  content: "\e90e";
}
.icon-flag-checkered:before {
  content: "\e90d";
}
.icon-checkbox-blank-circle-outline:before {
  content: "\e90b";
}
.icon-checkbox-marked-circle-outline:before {
  content: "\e90c";
}
.icon-weather-sunset:before {
  content: "\e90a";
}
.icon-file-check:before {
  content: "\e909";
}
.icon-file-pdf:before {
  content: "\e904";
}
.icon-file-powerpoint:before {
  content: "\e905";
}
.icon-file-video:before {
  content: "\e906";
}
.icon-file-word:before {
  content: "\e907";
}
.icon-file-excel:before {
  content: "\e903";
}
.icon-auto-fix:before {
  content: "\e901";
}
.icon-json:before {
  content: "\e900";
}
.icon-logout:before {
  content: "\e93f";
}
.icon-format_align_center:before {
  content: "\e934";
}
.icon-format_align_justify:before {
  content: "\e935";
}
.icon-format_align_left:before {
  content: "\e936";
}
.icon-format_align_right:before {
  content: "\e937";
}
.icon-arrow_right_alt:before {
  content: "\e932";
}
.icon-live_tv:before {
  content: "\e92f";
}
.icon-ramen_dining:before {
  content: "\e930";
}
.icon-celebration:before {
  content: "\e931";
}
.icon-branding_watermark:before {
  content: "\e92e";
}
.icon-fact_check:before {
  content: "\e92a";
}
.icon-home:before {
  content: "\e926";
}
.icon-rate_review:before {
  content: "\e925";
}
.icon-help:before {
  content: "\e923";
}
.icon-help_outline:before {
  content: "\e924";
}
.icon-timer:before {
  content: "\e91c";
}
.icon-power:before {
  content: "\e91d";
}
.icon-power_off:before {
  content: "\e91e";
}
.icon-settings_input_antenna:before {
  content: "\e91f";
}
.icon-sports_hockey:before {
  content: "\e920";
}
.icon-grid_on:before {
  content: "\e917";
}
.icon-view_day:before {
  content: "\e918";
}
.icon-view_week1:before {
  content: "\e91a";
}
.icon-drive_folder_upload:before {
  content: "\e915";
}
.icon-redeemcard_giftcard:before {
  content: "\e913";
}
.icon-hearing:before {
  content: "\e910";
}
.icon-subtitles:before {
  content: "\e911";
}
.icon-videocam:before {
  content: "\e912";
}
.icon-create_new_folder:before {
  content: "\e2cc";
}
.icon-delete_forever:before {
  content: "\e92b";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-restore_page:before {
  content: "\e929";
}
.icon-camera:before {
  content: "\e3af";
}
.icon-photo_camera:before {
  content: "\e412";
}
.icon-cloud_done:before {
  content: "\e2bf";
}
.icon-info:before {
  content: "\e88e";
}
.icon-info_outline:before {
  content: "\e88f";
}
.icon-room:before {
  content: "\e8b4";
}
.icon-person_pin:before {
  content: "\e55a";
}
.icon-pin_drop:before {
  content: "\e55e";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-tonality:before {
  content: "\e427";
}
.icon-wb_sunny:before {
  content: "\e430";
}
.icon-redo:before {
  content: "\e15a";
}
.icon-undo:before {
  content: "\e166";
}
.icon-visibility_off:before {
  content: "\e8f5";
}
.icon-archive:before {
  content: "\e149";
}
.icon-fiber_new:before {
  content: "\e05e";
}
.icon-ondemand_video:before {
  content: "\e63a";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-description:before {
  content: "\e873";
}
.icon-people:before {
  content: "\e7fb";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-content_paste:before {
  content: "\e14f";
}
.icon-low_priority:before {
  content: "\e16d";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-subscriptions:before {
  content: "\e064";
}
.icon-schedule:before {
  content: "\e8b5";
}
.icon-date_range:before {
  content: "\e916";
}
.icon-insert_invitation:before {
  content: "\e24f";
}
.icon-sync:before {
  content: "\e627";
}
.icon-art_track:before {
  content: "\e060";
}
.icon-check_box:before {
  content: "\e834";
}
.icon-check_box_outline_blank:before {
  content: "\e835";
}
.icon-chrome_reader_mode:before {
  content: "\e86d";
}
.icon-palette:before {
  content: "\e40a";
}
.icon-content_cut:before {
  content: "\e14e";
}
.icon-crop:before {
  content: "\e3be";
}
.icon-extension:before {
  content: "\e87b";
}
.icon-folder:before {
  content: "\e2c7";
}
.icon-star:before {
  content: "\e838";
}
.icon-insert_drive_file:before {
  content: "\e24d";
}
.icon-receipt:before {
  content: "\e8b0";
}
.icon-wb_iridescent:before {
  content: "\e436";
}
.icon-youtube_searched_for:before {
  content: "\e8fa";
}
.icon-movie:before {
  content: "\e013";
}
.icon-not_interested:before {
  content: "\e018";
}
.icon-play_arrow:before {
  content: "\e023";
}
.icon-play_circle_filled:before {
  content: "\e022";
}
.icon-play_circle_outline:before {
  content: "\e021";
}
.icon-playlist_add:before {
  content: "\e020";
}
.icon-add:before {
  content: "\e06a";
}
.icon-add_circle_outline:before {
  content: "\e06d";
}
.icon-create:before {
  content: "\e075";
}
.icon-remove_circle:before {
  content: "\e083";
}
.icon-remove_circle_outline:before {
  content: "\e082";
}
.icon-access_time:before {
  content: "\e08f";
}
.icon-insert_emoticon:before {
  content: "\e0e9";
}
.icon-insert_photo:before {
  content: "\e0ec";
}
.icon-security:before {
  content: "\e126";
}
.icon-looks_one:before {
  content: "\e193";
}
.icon-switch_camera:before {
  content: "\e1b0";
}
.icon-local_movies:before {
  content: "\e1e7";
}
.icon-local_offer:before {
  content: "\e1e8";
}
.icon-local_play:before {
  content: "\e1ec";
}
.icon-chevron_left:before {
  content: "\e20d";
}
.icon-chevron_right:before {
  content: "\e20c";
}
.icon-vpn_lock:before {
  content: "\e234";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-add_circle:before {
  content: "\e147";
}
.icon-alarm_on:before {
  content: "\e858";
}
.icon-apps:before {
  content: "\e5c3";
}
.icon-cloud_upload:before {
  content: "\e2c3";
}
.icon-beenhere:before {
  content: "\e52d";
}
.icon-border_color:before {
  content: "\e22b";
}
.icon-cancel:before {
  content: "\e5c9";
}
.icon-cast:before {
  content: "\e307";
}
.icon-chat:before {
  content: "\e0b7";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-wb_cloudy:before {
  content: "\e42d";
}
.icon-cloud_download:before {
  content: "\e2c0";
}
.icon-code:before {
  content: "\e86f";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-dashboard:before {
  content: "\e871";
}
.icon-delete:before {
  content: "\e872";
}
.icon-phonelink:before {
  content: "\e326";
}
.icon-directions_bike:before {
  content: "\e52f";
}
.icon-equalizer:before {
  content: "\e01f";
}
.icon-error:before {
  content: "\e002";
}
.icon-error_outline:before {
  content: "\e003";
}
.icon-expand_less:before {
  content: "\e5ce";
}
.icon-expand_more:before {
  content: "\e5cf";
}
.icon-explore:before {
  content: "\e87a";
}
.icon-first_page:before {
  content: "\e5dc";
}
.icon-group_add:before {
  content: "\e7f0";
}
.icon-group_work:before {
  content: "\e886";
}
.icon-http:before {
  content: "\e902";
}
.icon-lock:before {
  content: "\e897";
}
.icon-link:before {
  content: "\e157";
}
.icon-language:before {
  content: "\e894";
}
.icon-last_page:before {
  content: "\e5dd";
}
.icon-lens:before {
  content: "\e3fa";
}
.icon-list:before {
  content: "\e896";
}
.icon-lock_open:before {
  content: "\e898";
}
.icon-lock_outline:before {
  content: "\e899";
}
.icon-menu:before {
  content: "\e5d2";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-new_releases:before {
  content: "\e031";
}
.icon-pageview:before {
  content: "\e8a0";
}
.icon-pause_circle_outline:before {
  content: "\e036";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-person_add:before {
  content: "\e7fe";
}
.icon-playlist_add_check:before {
  content: "\e065";
}
.icon-power_settings_new:before {
  content: "\e8ac";
}
.icon-report:before {
  content: "\e160";
}
.icon-rotate_left:before {
  content: "\e419";
}
.icon-rotate_right:before {
  content: "\e41a";
}
.icon-search:before {
  content: "\e8b6";
}
.icon-settings:before {
  content: "\e8b8";
}
.icon-shop:before {
  content: "\e8c9";
}
.icon-shopping_basket:before {
  content: "\e8cb";
}
.icon-skip_next:before {
  content: "\e044";
}
.icon-skip_previous:before {
  content: "\e045";
}
.icon-stars:before {
  content: "\e8d0";
}
.icon-swap_horiz:before {
  content: "\e8d4";
}
.icon-swap_vert:before {
  content: "\e8d5";
}
.icon-thumb_up:before {
  content: "\e8dc";
}
.icon-timelapse:before {
  content: "\e422";
}
.icon-traffic:before {
  content: "\e565";
}
.icon-unfold_less:before {
  content: "\e5d6";
}
.icon-unfold_more:before {
  content: "\e5d7";
}
.icon-verified_user:before {
  content: "\e8e8";
}
.icon-view_carousel:before {
  content: "\e8eb";
}
.icon-view_list:before {
  content: "\e8ef";
}
.icon-view_module:before {
  content: "\e8f0";
}
.icon-view_week:before {
  content: "\e8f3";
}
.icon-filter_1:before {
  content: "\e3d0";
}
.icon-filter_9_plus:before {
  content: "\e3da";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-flip_to_back:before {
  content: "\e882";
}
.icon-flip_to_front:before {
  content: "\e883";
}
.icon-translate:before {
  content: "\e8e2";
}
.icon-folder_special:before {
  content: "\e617";
}
.icon-star_border:before {
  content: "\e83a";
}
.icon-photo_album:before {
  content: "\e411";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\e418";
}
.icon-sharewithpeople:before {
  content: "\e80d";
}
.icon-public:before {
  content: "\e80b";
}
.icon-line_style:before {
  content: "\e919";
}
.icon-file-zip:before {
  content: "\e908";
}
.icon-file:before {
  content: "\e908";
}
.icon-document:before {
  content: "\e908";
}
.icon-file-compressed:before {
  content: "\e908";
}
.icon-file-type:before {
  content: "\e908";
}
.icon-file-format:before {
  content: "\e908";
}
