.c6-main {
	overflow-y: scroll;
	padding-bottom: 20px;
}

/* Let the browser know that things will happen soon => greatly improved FPS */
/* See more: https://stackoverflow.com/questions/39261456/reducing-scroll-jank-when-using-overflow-y-scroll */
.c6-main > * {
	will-change: transform;
}

.c6-main::-webkit-scrollbar {
	width:  5px;
	height: auto;
}

/* TODO: Set class from user pref pane */
.c6-core.setting-wide-scrollbars .c6-main:hover::-webkit-scrollbar {
	width: 16px;
}

.c6-main::-webkit-scrollbar-thumb {
	background: var(--action-color);
}

.c6-main::-webkit-scrollbar-thumb:hover {
	background: var(--action-hl-color);
}

.c6-main::-webkit-scrollbar-track {
	margin-bottom: 20px;
}

.c6-main.pad26::-webkit-scrollbar-track {
	margin-top: 26px;
}

.c6-main.pad10::-webkit-scrollbar-track {
	margin-top: 10px;
}

/* */
.c6-modal .c6-main {
	padding-bottom: 0;
}

.c6-modal .c6-main::-webkit-scrollbar-track {
	margin-bottom: 0;
}

/* Handle cases where we have a top nav bar (like in the Comet admin UI) */
.c6-module > .c6-topbar ~ .c6-app.c6-layout-grid .c6-main {
	/* padding-bottom: 90px; */
	padding-bottom: 55px;
}

.c6-module > .c6-topbar ~ .c6-app.c6-layout-grid .c6-main::-webkit-scrollbar-track {
	/* margin-bottom: 91px; */
	margin-bottom: 56px;
}

.c6-module > .c6-topbar ~ .c6-app.c6-layout-grid .c6-main.pad26::-webkit-scrollbar-track {
	margin-top: 26px;
}

/* When we have small screen widths we want to scroll the whole page for better UX */
/* html.product-internal-schedule */
@media screen and (max-width: 450px) {
	html,
	body,
	#root,
	.c6-core,
	.c6-core > div:not(.alert-container):not(.alert-network) {
		overflow: initial;
	}

	.c6-main {
		overflow-y: initial;
		height: auto !important;
	}
}