.c6-suggestions {
	position: relative;
	width: 100%;
}

.c6-suggestions ul {
	position: absolute;
	left: -4px;
	right: -4px;
	top: 3px;
	z-index: 20;

	max-height: 300px;
	overflow-y: scroll;

	padding: 6px 0;
	margin: 0;

	list-style-type: none;
	background-color: var(--area-lightest-color);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16),
				0 2px 4px rgba(0, 0, 0, 0.23);

	font-size: 14px;
	font-weight: 400;
}

.c6-suggestions li {
	padding: 8px 15px;
	margin: 0;
	cursor: pointer;

	background-color: var(--area-lightest-color);
	transition: background-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.c6-suggestions li.active-suggestion {
	background-color: rgba(0, 0, 0, 0.1);
}
.c6-suggestions li.disabled-suggestion {
	background-color: rgba(0, 0, 0, 0.2);
	color: indianred;
}
.c6-suggestions li.no-results {
	cursor: default;
}