/* .c6-amp .vjs-big-play-button {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
} */

.c6-amp {
	position: relative;
}

.c6-amp .amp-c6-custom-button {
	display: table-cell !important;
	/* vertical-align: middle; */
	width: 50px !important;
}

.c6-amp > div > div {
	pointer-events: none !important;
}
.c6-amp > div > div.ready {
	pointer-events: all !important;
}

.c6-amp .watermark {
	position: absolute;
	z-index: 2147483647;
	top: 70%;
	left: 50%;
	transform: translateX(-50%);

	font-size: 70px;
	opacity: 0.7;
	color: white;
	text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);

	pointer-events: none;
}

.c6-amp .vjs-big-play-button {
	display: none;
}
.c6-amp.has-sources .vjs-big-play-button {
	display: initial;
}


@media screen and (max-width: 720px) {
	.c6-amp .watermark {
		font-size: 30px;
	}
}