.drawer-toggler {
	color: var(--action-text-color) !important;
	padding: 0;
	background: none;
	border: 0;
	outline: 0;
	font-size: inherit;
	cursor: pointer;
	font-family: var(--default-font);
	-webkit-appearance: none;
	user-select: none;
	z-index: 10;
}

.drawer-toggler.drawer-open {
	z-index: 1500;
}

.drawer-toggler span.drawer-toggler-title {
	line-height: 36px;
}
.drawer-toggler span.drawer-toggler-module {
	line-height: 36px;
    color: var(--action-text-color);
	margin-left: 5px;
	-webkit-font-smoothing: antialiased;
}

.drawer-toggler span:before {
    font-family: "icomoon";
    padding-left: 5px;
	margin-right: 5px;
	font-size: 24px;
	position: relative;
	top: 5px;
}

.drawer-toggler.drawer-open span:before,
.drawer-toggler:hover span:before {
	color: var(--area-hl-color);
}

/* Version update */
@keyframes opacityPulse {
    /* 0% {opacity: 0.5;} */
    50% { color: var(--attention-color); border-bottom-color: var(--attention-color);}
    /* 100% {opacity: 0.5;} */
}

.newVersionAvailable {
	background-color: var(--area-color);
}

.newVersionAvailable a {
	font-weight: bold;
	color: var(--area-hl-color);
	border-bottom-color: var(--area-hl-color);
}

.newVersionAvailable a:not(:hover) {
	animation: opacityPulse 1.5s ease-in-out;
	animation-iteration-count: infinite;
	opacity: 1;
}

.env-acceptance .newVersionAvailable a:not(:hover) {
	animation-duration: .3s;
	background-color: #0002ff;
}