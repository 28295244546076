.c6-status-group {
	text-align: center;
}

.c6-status-group .c6-status + span {
	display: block;
	font-size: .85rem;
	color: var(--text-light-color);
	text-transform: uppercase;
	margin-top: 2px;
	text-align: center;
}

.c6-status {
	display: inline-block;
	color: var(--accent-color);
	padding: 5px 8px;
	text-align: center;
	border-radius: var(--comet-border-radius);
}

.c6-theme-dark .c6-status {
	color: var(--action-text-color);
}

.c6-status + .c6-status {
	margin-left: 1px;
}

.c6-status a {
	color: inherit;
	text-decoration: none;
	border-bottom: 1px dotted rgba(255, 255, 255, .75);
}