/* ================== */
/* ===== GENERAL ==== */
/* ================== */
.todo-activity {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	padding-right: 5px;

	overflow-y: auto;
}

.todo-activity button {
	color: white !important;
}

.todo-activity h1:first-child,
.todo-activity p:first-child {
	margin-top: 5px;
	color: var(--text-semilight-color);
}

.todo-activity section > div {
	display: flex;
	background-color: var(--content-bg-color);
	padding: 10px;
}

.todo-activity .right {
	display: flex;
	align-items: center;
}


/* ================== */
/* ==== DUE DATE ==== */
/* ================== */
.todo-activity .due-date h1 {
	margin-bottom: 5px;
}
.todo-activity .due-date p {
	color: var(--text-lighter-color);
	margin: 0;
}
.todo-activity .due-date > h2 {
	margin-top: 33px;
}


/* ================== */
/* ==== COMMENTS ==== */
/* ================== */
.todo-activity section > .c6-comments {
	display: block;
	background-color: var(--bg-color);
	padding: 0;
}

.todo-activity .c6-comments > .new-comment {
	margin-top: 10px;
	background-color: var(--content-bg-color);
}