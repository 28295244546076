.c6-spinner {
	position: absolute;
	display: inline-block;
	background-color: var(--area-color);
	transition: background-color ease-in-out .15s;
	height: 36px;
	width: 30px;
	top: 0;
	left: 0;
	pointer-events: none;
}

.c6-spinner span {
	display: inline-block;
	/*font-size: 10px;*/
	margin: 0 auto;
	text-indent: -9999em;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background: #fff;
	background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 50%);
	position: absolute;
	top: 7px;
	left: 6px;
	animation: load3 .5s infinite linear;
	animation-delay: .15s;
	transform: translateZ(0);
}
.c6-spinner span:before {
	width: 50%;
	height: 50%;
	background: #fff;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}
.c6-spinner span:after {
	background: var(--area-color);
	transition: background-color ease-in-out .15s;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

@keyframes load3 {
	  0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}