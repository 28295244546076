
.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    pointer-events: none;
    /*text-align: center;*/
    margin: 0 auto;
}

.alert-container .alert {
    pointer-events: all;
    min-width: 300px;
    width: 700px;
    max-width: 80%;
    min-height: 45px;

    margin: 5px auto;
    padding: 15px;

    -webkit-box-shadow: 0px 2px 3px 0px rgba(110,110,110,1);
    -moz-box-shadow: 0px 2px 3px 0px rgba(110,110,110,1);
    box-shadow: 0px 2px 3px 0px rgba(110,110,110,1);
    border-radius: var(--comet-border-radius);

    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.5s ease;

    position: relative;
}

.alert-container .alert.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.alert-container .alert .action-left, .action-right {
    position: absolute;
    top: 0;
    height: 45px;
    width: 50px;
	min-width: 0;
	
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1rem;
}

.alert-container .alert .action-left {
    left: 0
}

.alert-container .alert .action-right {
    right: 0;
}

.alert-container .alert > span {
    max-width: calc(100% - 70px);
    
    word-wrap: break-word;
    display: block;
}

.alert-container .alert > span.message {
    padding-left: 40px;
}

.alert-container .alert > span.message a {
    padding-left: 5px;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.alert-container .alert > div.details {
    display: inline-block;
    padding-top: 20px;
}

.alert-container .alert > div.details > strong {
    font-size: 120%;
}

.alert-container .alert > div.details.hide {
    display: none;
}