.c6-switch {
	transition: opacity .15s ease-in-out;
	user-select: none;
	cursor: default;
	display: inline-block;
}

.c6-switch[disabled] {
	opacity: 0.25;
	pointer-events: none; /* Disables hover states on our labels */
}

.c6-switch > span {
	margin-right: 0.25rem;
	color: var(--text-light-color);
	text-transform: uppercase;
    font-size: .85rem;
}

.c6-switch label {
	display: inline-block;
	cursor: pointer;
}

.c6-switch label.inactive,
.c6-switch label.readOnly {
	color: var(--text-lighter-color);
	cursor: default;
}

.c6-switch label input {
	visibility: hidden;
	display: none;
}

.c6-switch label span {
	padding: 2px 5px;
	margin: 0 2px 0 0;
	border-radius: var(--comet-border-radius);
}

.c6-switch label:first-child span {
	margin-left: 0;
}

.c6-switch:not(.isUnselected) input:checked + span {
	color: var(--action-text-color);
	background-color: var(--action-hl-color);
}

.c6-switch:not(.isUnselected) input:checked + span {
	background-color: var(--action-color);
}

.c6-switch-description {
	font-size: .85rem;
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	.c6-switch label span:hover,
	.c6-switch:not(.isUnselected) input:checked + span:hover  {
		color: var(--action-text-color);
		background-color: var(--action-hl-color);
	}
}

.c6-dropdown + .c6-switch,
.c6-switch + .c6-dropdown,
.c6-dropdown + .c6-dropdown,
.c6-switch + .c6-switch {
	margin-left: 1.5rem;
}

/* Sub switches */
.c6-switch .c6-switch {
	background-color: var(--bg-inset-color);
	color: var(--action-color);
	margin-left: -1px;
	height: 20px;
}

.c6-switch .c6-switch::before {
	content: "\25B6";
	display: inline-block;
	height: 20px;
	color: var(--accent-color);
	background-color: var(--action-color);
    font-size: .5rem;
	line-height: 23px;
	vertical-align: top;
	padding-right: 3px;
	margin-left: -1px;
	border-radius: 0 4px 4px 0;
}

.c6-switch .c6-switch.isUnselected label span {
	background-color: var(--bg-inset-color);
	color: var(--text-color);
} 

.c6-switch label:hover + .c6-switch::before {
	background-color: var(--action-hl-color);
}

.c6-switch .c6-switch label:first-child span {
	margin-left: 2px;
}

.c6-switch label.sel.has-sub span {
	border-radius: 4px 0 0 4px;
}

.c6-switch .c6-switch label:last-child span {
	margin-right: 0;
}

/* Picker */
.c6-switch .c6-pick-toggle span,
.c6-switch .c6-pick-toggle input:checked + span {
	margin-right: 2px;
	color: var(--action-color);
	background-color: var(--area-light-color);
}

.c6-pick-toggle span {
	min-width: 50px;
	text-align: center;
}

/* Only use hover effects on devices supporting hover (HACK?) */
@media (hover), not all and (-moz-touch-enabled: 1) {
	.c6-switch .c6-pick-toggle:hover ~ label span {
		color: var(--accent-color);
		background-color: var(--action-hl-color);
	}
}