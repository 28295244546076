.c6-osd-todo .todo-ratings {
	padding: 5px 0 0 15px;
}

.c6-osd-todo .todo-ratings > h2 {
	margin-bottom: 20px;
}



/* Content actions */
.c6-osd-todo .c6-content-actions {
	align-self: unset;
	padding: 0;
	justify-content: flex-end;
	flex-shrink: 1;
	flex-grow: 0;
	flex-basis: auto;
	margin-left: auto;
}

.c6-osd-todo .c6-content .c6-text-unapproved .c6-link {
	color: inherit;
	border-bottom-color: inherit;
}

.c6-osd-todo .c6-content .c6-text-unapproved .c6-link:hover {
	color: var(--accent-color);
	border-bottom-color: transparent;
}


/* Content actions */
.c6-osd-todo .c6-content-actions > * {
	max-width: 100px;
	/*height: 2rem;*/
	transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.c6-osd-todo .c6-content-actions *:before {
	margin-right: 1px;
	transition: color .15s ease-in-out;
}

/* TODO: Breaks scrolling on Chrome Windows due to the list scroll bar repainting (yes, mind blown) when we switch the icon */
/* .c6-osd-todo .c6-item:hover .c6-content-actions > button:not([disabled]):before {
	content: "\E075";
} */

.c6-osd-todo .c6-content-actions button {
	-webkit-appearance: none;
	background: none;
	border: none;
	font-family: inherit;
	font-size: inherit;
	border-radius: 0;
	padding: 0 15px;
}
.c6-osd-todo .c6-content-actions > div {
	padding: 0 0 0 15px;
	position: relative;
	max-width: 80px;
}
.c6-osd-todo .c6-content-actions > div:before {
	position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.c6-osd-todo .c6-content-actions > div > button {
	height: 50%;
	width: 100%;
	cursor: pointer;
	padding: 0 5px;
}
.c6-osd-todo .c6-content-actions button span {
	white-space: nowrap;
}
.c6-osd-todo .c6-content-actions button:not([disabled]) span {
	border-bottom: 1px solid transparent;
}

.c6-osd-todo .c6-item:hover .c6-content-actions button:not([disabled]) span {
	border-bottom: 1px dotted;
}

.c6-osd-todo .c6-content-actions button:hover {
	background-color: var(--action-color);
}

.c6-osd-todo .c6-content-actions button:hover span,
.c6-osd-todo .c6-content-actions button:hover small,
.c6-osd-todo .c6-content-actions button:hover:before {
	color: var(--accent-color) !important;
	/* border-bottom: none !important; */
}